<template>
    <div class="blog">
        <div class="blog__content" data-aos="fade-up">
            <div class="blog__content__sectionName">
                <h3>Blog Kompre</h3>
                <div class="blog__content__sectionName__line"></div>
                <a href="https://blog.kompre.pl/">Zobacz więcej</a>
            </div>
            <div class="blog__content__references">
                <div v-for="post in posts" :key="post.title" class="blog__content__references__reference">
                    <a :href="`https://blog.kompre.pl/post/${post.id}`">
                        <div class="blog__content__references__reference__shrink">
                            <img :src="post.image" alt="" />
                        </div>
                        <div class="blog__content__references__reference__textBox">
                            <div class="blog__content__references__reference__textBox__texts">
                                <p class="blog__content__references__reference__textBox__texts__post">POST</p>
                                <div class="blog__content__references__reference__textBox__texts__content">
                                    <p class="blog__content__references__reference__textBox__texts__content__title">{{ post.title }}</p>
                                    <p class="blog__content__references__reference__textBox__texts__content__shortText">{{ post.shortText }}</p>
                                </div>
                            </div>
                        </div>
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {
  db
} from '../configs/firebase'

export default {
  name: 'Home',
  data() {
    return {
      posts: []
    }
  },
  components: {
  },
  methods: {
    async getPosts()
    {
      this.posts = [];
      try {
        const result = await db.collection("BlogPosts").where("active","==",true).orderBy('createdDate','desc').limit(3).get();
        for(let i=0;i<result.docs.length;i++)
        {
          this.posts.push(result.docs[i].data());
        }
        // console.log(this.posts);
      }
      catch(error)
      {
        console.log(error);
      }
    }
  },
  created()
  {
    this.getPosts();
  }
}
</script>

<style lang="scss">

@import '../styles/variables';

  .blog{
    margin-top: 1.563rem;
    margin-bottom: 1.563rem;
    width: 100vw;
    font-size: 16px;
    &__content{
        padding-left: 5%;
        padding-right: 5%;
        width: 100%;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
        &__sectionName{
            display: flex;
            align-items: center;
            justify-content: space-between;
            &__line{
                flex: 1 1;
                border-bottom: 1px solid #d5d5d5;
            }
            h3{
                font-size: 1.25rem;
                font-weight: 500;
                margin: 0;
                margin-right: 1.953rem;
            }
            a{
                font-size: 1rem;
                margin: 0;
                font-weight: 400;
                margin-left: 1.953rem;
                transition: all 0.1s ease-in-out;
            }
            a:hover{
                color: $red;
                cursor: pointer;
            }
        }
        &__references{
            width: 100%;
            display: flex;  
            justify-content: space-between;
            margin-top: 1.25rem;
            margin-bottom: 1.563rem;
            -webkit-box-sizing: border-box;
            -moz-box-sizing: border-box;
            box-sizing: border-box;
            &__reference{
                display: flex;
                flex-direction: column;
                width: 32%;
                transition: box-shadow 0.4s ease-in-out;
                overflow: hidden;
                border-radius: 0.5rem;
                 --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
                box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
                &__shrink{
                    flex-shrink: 0;
                    img{
                        object-fit: cover;
                        width: 100%;
                        height: 12rem;
                    }
                }
                &__textBox{
                    display: flex;
                    flex: 1 1 0%;
                    padding: 1.25rem;
                    flex-direction: column;
                    justify-content: space-between;
                    &__texts{
                        flex: 1 1 0%;
                        &__post{
                            color: $red;
                            font-size: 0.8rem;
                            font-weight: 500;
                            margin: 0;
                        }
                        &__content{
                            display: block;
                            margin-top: 0.41rem;
                            &__title{
                                font-size: 1rem;
                                font-weight: 500;
                                margin: 0;
                            }
                            &__shortText{
                                font-size: 0.8rem;
                                line-height: 1.5rem;
                                color: $gray;
                                margin: 0;
                                margin-top: 0.328rem;
                            }
                        }
                    }
                }
            }
            &__reference:hover
            {
                box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
                cursor: pointer;
            }
        }
    }
  }
  

  @media (max-width: 769px)
    {
        .blog__content__references{
            flex-direction: column;
            &__reference{
                width: 100%;
                margin-bottom: 1.563rem;
            }
            &__reference:last-child{
                margin-bottom: 0;
            }
        }
    }

</style>
