<template>
    <div class="slider">
        <div class="slider__container">
            <div class="slider__container__slide animate__animated animate__fadeIn animate__delay-0.2s" v-show="showSlide">
                <div class="slider__container__slide__leftHalf">
                    <img class="animate__animated animate__fadeInLeft animate__delay-0.5s" :src="currentSlide.imgUrl" :alt="currentSlide.alt">
                </div>
                <div class="slider__container__slide__rightHalf">
                    <div class="slider__container__slide__rightHalf__subtext animate__animated animate__fadeInRight animate__delay-0.5s">
                        <h3>{{currentSlide.subtitle}}</h3>
                    </div>
                    <div class="slider__container__slide__rightHalf__cta animate__animated animate__fadeInRight animate__delay-0.6s">
                        <h1>{{currentSlide.title}}</h1>
                    </div>
                    <div class="slider__container__slide__rightHalf__link animate__animated animate__fadeInRight animate__delay-0.5s" >
                        <div class="slider__container__slide__rightHalf__link__anchor">
                            <a :href="currentSlide.link.includes('https://') ? currentSlide.link : ('https://' + currentSlide.link)">
                                {{currentSlide.anchorText}}
                            </a>
                        </div>
                        <div class="slider__container__slide__rightHalf__link__icon">
                            <i class="fi-rr-angle-small-right"></i>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="slider__control">
            <div class="slider__control__dots">
                <div v-for="slide in slides" :key="slide.title" :class="{'slider__control__dots__dot': true,'slider__control__dots__dotActive': currentSlide === slide}" @click="slidePick(slide, slides.indexOf(slide))"></div>
            </div>
        </div>
    </div>
    
</template>
<script>
// Imports
import {
  db
} from '../configs/firebase'

export default {
  data() {
    return {
        slides: [],
        slideNum: 0,
        currentSlide: {
            link: '/'
        },
        sliderTime: 5000,
        showSlide: true,
        refreshIntervalId: null,
    }
  },
  methods: {
        slidePick(slide, slideID){
            clearInterval(this.refreshIntervalId)
            this.showSlide = false
            this.slideNum = slideID
            this.currentSlide = slide
            this.slider()
            setTimeout(()=>
            {
                this.showSlide = true;
            },200)
        },
      changeSlide()
      {
          this.currentSlide = this.slides[this.slideNum];
          setTimeout(()=>
          {
              this.showSlide = true;
          },200)
      },
      slider()
      {
              this.refreshIntervalId = setInterval(()=>{
                this.slideNum += 1;
                  if(this.slideNum > this.slides.length-1)
                  {
                      this.showSlide = false;
                      this.slideNum = 0;
                      this.changeSlide();
                  }
                  else
                  {
                      this.showSlide = false;
                      this.changeSlide();
                  }
              },this.sliderTime)
      }

  },
  async beforeCreate()
  {
        //Get Categories from db
        await db.collection('SliderRules').doc('Rules').get().then(async (data)=>
        {
            let rules = data.data()
            // Unpack categories
            for(let i=0; i < rules.DisplayQueueV1.length; i++)
            {
                if(rules.DisplayQueueV1[i].active === true){
                    await db.collection('Slides').doc(rules.DisplayQueueV1[i].id).get().then((data)=>
                    {
                        // Unpack categories
                        if(data.data().display === true){
                        this.slides.push(data.data());
                        }
                        
                    }).catch((error)=>
                    {
                        console.log('Error: Cannot get categories. More:')
                        console.log(error);
                    })
                }
            }
            this.currentSlide = this.slides[0]
            this.slider();
            
        }).catch((error)=>
        {
            console.log('Error: Cannot get categories. More:')
            console.log(error);
        })

        // db.collection('Slides').get().then((data)=>
        // {
        //     // Unpack categories
        //     for(let i=0; i < data.docs.length; i++)
        //     {
        //         if(data.docs[i].data().display === true){
        //         this.slides.push(data.docs[i].data());
        //         }
        //     }
        //     this.currentSlide = this.slides[0]
        //     this.slider();
            
        // }).catch((error)=>
        // {
        //     console.log('Error: Cannot get categories. More:')
        //     console.log(error);
        // })
  }
}
</script>
<style lang="scss" scoped>
@import '../styles/variables';



h1 {
    text-align: center;
    user-select: none;
    margin: 0;
    padding: 0;
    font-family: 'Poppins', sans-serif !important;
    font-weight: 500;
    font-size: 2.441rem;
}

h3
{
    text-align: center;
    font-weight: 400;
    font-size: 1.953rem;
    margin: 0;
}

.slider
{
    width: 100vw;
    height: 80vh;
    margin-top: 1rem;
    padding-left: 5%;
    padding-right: 5%;
    display: flex;
    justify-content: center;
    align-items: center;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    position: relative;
    overflow-x: hidden;
    &__control{
        position: absolute;
        height: 0.64rem;
        width: 100%;
        bottom: 0.64rem;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
        &__dots{
            display: flex;
            justify-content: center;
            align-content: center;
            &__dot{
                height: 0.64rem;
                width: 0.64rem;
                background: black;
                opacity: 0.2;
                margin-right: 0.41rem;
                border-radius: 100px;
                transition: all 0.4s ease-in-out;
                cursor: pointer;
            }
            &__dot:hover{
                opacity: 0.5 !important;
            }
            &__dot:last-child{
                margin-right: 0;
            }
            &__dotActive{
                opacity: 1 !important;
            }
        }
    }
    &__container
    {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
        &__slide
        {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 100%;
            -webkit-box-sizing: border-box;
            -moz-box-sizing: border-box;
            box-sizing: border-box;
            &__leftHalf
            {
                display: flex;
                justify-content: center;
                flex-direction: column;
                align-items: center;
                width: 50%;
                height: 100%;

                img
                {
                    max-width: 75%;
                    max-height: 100%;
                }
            }
            

            &__rightHalf
            {
                display: flex;
                justify-content: center;
                flex-direction: column;
                align-items: flex-start;
                width: 50%;
                height: 100%;

                &__subtext
                {
                    user-select: none;
                    font-weight: 400;
                    font-size: 1.953rem;
                }


                &__link
                {
                    user-select: none;
                    margin-top: 0.8rem;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    &__anchor:hover + &__icon,&__anchor:hover
                    {
                        color: $redHover;
                        transform: translateX(5px);
                    }

                    &__anchor
                    {
                        text-align: center;
                        color: $red;
                        text-decoration: underline;
                        font-size: 1.563rem;
                        transition: all 0.4s ease-in;
                    }

                    &__icon
                    {
                        color: $red;
                        transition: all 0.4s ease-in;
                        i{
                            display: flex;
                            justify-content: center;
                            align-items: center;
                        }
                    }
                }


            }
        }
    
    }
}

@media (max-width: 1024px) {
    h1 {
        font-size: 1.563rem;
    }

    h3
    {
        font-size: 1.25rem;
    }
}

@media (max-width: 769px)
{
h1 {
    font-size: 1.953rem;
}

.slider
{
    &__control{
        bottom: 0;
    }
    margin-top: 1.953rem;
    &__container
    {


        &__slide
        {
            flex-direction: column;

            &__leftHalf
            {
                width: 100%;


                img
                {
                    max-width: 75%;
                    max-height: 100%;
                }
            }
            

            &__rightHalf
            {
                margin-top: 1em;
                display: flex;
                justify-content: center;
                flex-direction: column;
                align-items: center;
                width: 100%;
                height: 100%;

                &__subtext
                {
                    user-select: none;
                    font-weight: 400;
                    font-size: 1.953rem;
                }



            }
        }
    
    }
}
}


</style>