<template>
    <div class="backdrop" @click.self="closeModal">
        <div class="modal" :class="{ sale: theme === 'sale'}">

        </div>
    </div>
</template>


<script>
export default {
    data() {
        return {
            
        }
    },
    methods: {
        closeModal() {
            this.$emit('close')
        },
        appendMapCss()
        {

        }
    }
}
</script>

<style lang="scss" scoped>
@import '../styles/variables';





.icon
{
    font-size: 1.563rem;
}

.icon:hover
{
    color: $red;
    cursor: pointer;
}

.modal{
    display: flex;
    flex-direction: column;
    width: 95vw;
    height: 95vh;
    overflow: hidden;
    padding: 20px;
    margin: 100px auto;
    background: white;
    border-radius: 10px;
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);




    &__close
    {
        display: flex;
        margin-bottom: 1rem;
        justify-content: flex-end;
        align-items: center;
    }
}
.backdrop {
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10000;
    position: fixed;
    background: rgba(0,0,0,0.5);
    width: 100%;
    height: 100%;
}
.modal h1 {
    color: #03cfb4;
}
.modal p{
    font-style: normal;
}
.modal .actions{
text-align: center;
margin: 30px 0 10px 0;
}
.modal .actions a {
color: #333;
padding: 8px;
border: 1px solid #eee;
border-radius: 4px;
text-decoration: none;
margin: 10px
}
.modal.sale {
    background-color: crimson;
    color: white;
}
.modal.sale h1 {
    color:white;
}
.modal.sale .actions{
color: white;
}
.modal.sale .actions a{
    color: white;
}

.current
{
    border: 1px solid $red;
}

.block
{
    display: block;
}

.disabledIcon
{
    color: $border;
}

.disabledIcon:hover
{
    color: $border;
}
</style>