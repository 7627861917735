<template>
    <div class="order">
        <div class="order__logo">
            <router-link to="/">
                <img src="../assets/kompreLogo-min.png" alt="Komputery poleasingowe, tanie laptopy oraz monitory powystawowe.">
            </router-link>
        </div>
        <div class="order__content" v-if="loaded">
            <div class="order__content__id">
                <div class="order__content__id__header">Karta zamówienia</div>
                <div class="order__content__id__section">
                    <div class="order__content__id__section__snippet"><i class="fi fi-rr-duplicate"></i><span>ID: {{order.firestoreID}}</span><i class="fi fi-rr-credit-card"></i> <span>{{order.paymentName}}</span> <i class="fi fi-rr-location-alt"></i> <span>{{order.deliveryName}}</span><i class="fi fi-rr-envelope"></i><span>{{order.clientCredentials.mail}}</span></div>
                </div>
                <div class="order__content__id__section">
                    <div class="order__content__id__section__name">Dane klienta</div>
                    <div class="order__content__id__section__val">{{order.clientCredentials.name}} {{order.clientCredentials.surname}}</div>
                    <div class="order__content__id__section__val--notBold" v-if="order.clientCredentials.address.length>0">{{order.clientCredentials.address}}, {{order.clientCredentials.postCode}} {{order.clientCredentials.city}}</div>
                    <div class="order__content__id__section__val--notBold">Tel: {{order.clientCredentials.phone}}</div>
                </div>
                <div class="order__content__id__section" v-if="order.clientCredentials.company.length>0">
                    <div class="order__content__id__section__name">Dane firmy</div>
                    <div class="order__content__id__section__val">{{order.clientCredentials.company}}</div>
                    <div class="order__content__id__section__val--notBold" v-if="order.clientCredentials.taxAddress.length>0">{{order.clientCredentials.taxAddress}}, {{order.clientCredentials.taxPostCode}} {{order.clientCredentials.taxCity}}</div>
                    <div class="order__content__id__section__val--notBold">NIP: {{order.clientCredentials.taxNum}}</div>
                </div>
                <div class="order__content__id__section">
                    <div class="order__content__id__section__name">Zakupione przedmioty</div>
                    <div class="order__content__id__section__productColumn">
                        <div v-for="(cartItem,indx) in order.cart" :key="indx">
                            <div class="order__content__id__section__productColumn__base">
                                <div class="order__content__id__section__productColumn__base__nameRow">
                                    <div class="order__content__id__section__productColumn__base__nameRow__indx">{{indx+1}}.</div>
                                    <div class="order__content__id__section__productColumn__base__nameRow__image"><img :src="cartItem.product.mainImage.url"></div>
                                    <div class="order__content__id__section__productColumn__base__nameRow__name">{{cartItem.quantity}} x {{cartItem.product.name}} <span> - {{cartItem.positionPrice}} PLN</span> <router-link :to="`/produkt/${cartItem.product.uniqueID}`" target="_blank">Zobacz</router-link></div>
                                </div>
                                <div class="order__content__id__section__productColumn__base__addons" v-if="cartItem.addons.length>0">
                                    <div class="order__content__id__section__productColumn__base__addons__addon" v-for="(addon,addonIndx) in cartItem.addons" :key="addonIndx"> - {{addon.addonName}}: <span>{{addon.optionName}}</span></div>        

                                </div>

                            </div>
                        </div>
                        <div>

                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</template>
<script>
    // Imports
    import {
    db
    } from '../configs/firebase'

    export default {
        data() {
        return {
            order: {
            },
            loaded: false
        }
        },
        methods: {
            async getOrder()
            {
                try {
                    const result = await db.collection("Orders").doc(this.$route.params.id).get();
                    this.order = result.data();
                    this.loaded = true;
                }
                catch (error)
                {
                    console.log(error);
                }
            }
        },
        created()
        {
            this.getOrder();
        },
        mounted()
        {
            window.scrollTo(0,0);
        },
    }
</script>
<style lang="scss" scoped>
    @import '../styles/variables';

    .order
    {
        width: 100vw;
        user-select: none;

        &__logo
        {
            display: flex;
            align-items: center;
            justify-self: center;
            width: 100%;
            height: 2.441rem;
            padding-left: 1rem;
            
            border-bottom: 1rem;
            box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);

            a
            {
                display: flex;
                align-items: center;
            }
            img
            {
                max-width: 
                7.451rem;
            }
        }
        
        &__content
        {
            width: 95%;
            margin-top: 1rem;   
            padding-left: 1rem;

            &__id
            {
                font-size: 1rem;

                &__header
                {
                    font-size: 1.25rem;
                    
                }

                &__section
                {
                    margin-bottom: 1rem;

                    &__productColumn
                    {
                        display: flex;
                        flex-direction: column;

                        &__base
                        {
                            margin-top: 1rem;
                            display: flex;
                            flex-direction: column;

                            &__nameRow
                            {
                                display: flex;
                                align-items: center;

                                &__indx
                                {
                                    font-size: 0.8rem;
                                    margin-right: 1rem;
                                }

                                &__name
                                {
                                    font-size: 0.8rem;
                                }
                                
                                span
                                {
                                    font-size: 0.8rem;
                                    color: $gray;
                                    margin-right: 1rem;
                                }

                                a
                                {
                                    color: $red;
                                    text-decoration: underline;
                                }

                                a:hover
                                {
                                    color: $gray;
                                }

                                img {
                                    max-width: 45px;
                                    max-height: 45px;
                                    margin-right: 1rem;
                                }
                            }

                            &__addons
                            {
                                &__addon
                                {
                                    font-size: 0.8rem;
                                    color: $gray;
                                    margin-left: 1rem;
                                    margin-top: 0.512rem;

                                    span
                                    {
                                        font-weight: 500;
                                        color: $black;
                                    }
                                }
                            }
                        }
                    }

                    &__name
                    {
                        font-size: 0.8rem;
                        color: $gray;
                    }

                    &__val
                    {
                        display: flex;
                        align-items: center;
                        font-weight: 500;

                        &--small
                        {
                            font-size: 0.64rem;
                            color: $gray;
                        }

                        &--notBold
                        {
                            color: $gray;
                            font-weight: 400;
                        }
                    }

                    &__snippet
                    {
                        display: flex;
                        align-items: center;
                        color: $gray;
                        font-size: 0.8rem;
                        border-bottom: 1px solid $border;
                        padding-bottom: 0.41rem;

                        i
                        {
                            padding-right: 0.512rem;
                        }

                        span
                        {
                            
                            padding-right: 0.512rem;
                        }
                    }
                }
            }
        }
    }


    @media (max-width: 700px)
    {
        .order__content__id__section__snippet
        {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
        }
    }
</style>