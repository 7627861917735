<template>
<div class="mainSettings">
    <div classs="mainSettings__componentLoaded">
        <span class="md-display-1">Główna</span>
        <div class="mainSettings__version" v-if="contentLoaded">
            <md-toolbar :md-elevation="1">
                <span class="md-headline">Wersja</span>
            </md-toolbar>
            <div class="mainSettings__version__info">
                <span class="md-title">Wersja CMS</span>
                <span class="subheading">Aktualna wersja systemu zarządzania treścią.</span>
                <div class="mainSettings__version__info__patch">
                    <span class="md-body-1">Version: {{version.versionCMS}}</span>
                </div>
                <md-divider></md-divider>
            </div>
            <div class="mainSettings__version__info">
                <span class="md-title">Wersja Klienta.</span>
                <span class="subheading">Aktualna wersja sklepu po stronie klienta.</span>
                <div class="mainSettings__version__info__patch">
                    <span class="md-body-1">Version: {{version.versionClient}}</span>
                </div>
                <md-divider></md-divider>
            </div>
            <div class="mainSettings__version__info">
                <span class="md-title">ChangeLog</span>
                <span class="subheading">Ostatnie zmiany w systemie sprzedaży.</span>
                <div class="mainSettings__version__info__patch">
                    <div class="mainSettings__version__info__patch__change" v-for="change in changelog" :key="change.message">
                        <div class="mainSettings__version__info__patch__change__date"><span class="md-caption">{{change.date}}</span></div>
                        <div class="mainSettings__version__info__patch__change__message"><span class="md-body-1">{{change.message}}</span></div>
                    </div>
                </div>
            </div>
        </div>
        <div class="mainSettings__version">
            <md-toolbar :md-elevation="1">
                <span class="md-headline">Feedback</span>
            </md-toolbar>
            <div class="mainSettings__version__info">
                <span class="md-title dateOfChangeLog">Nowe zgłoszenie</span>
                <span class="subheading">Zgłoś błąd w funkcjonowaniu systemu lub poproś o nową funkcjonalność.</span>
                    <div class="mainSettings__version__feedbackForm">
                        <span class="md-title">Tytuł</span>
                        <md-field id="naming" :class="{'md-invalid': errors.lengthError}">
                            <label>Nazwa</label>
                            <md-input v-model="feedback.title" maxlength="60"></md-input>
                            <span class="md-error">Tytuł musi być wypełniony.</span>
                        </md-field>
                        <span class="md-title">Wiadomość</span>
                        <md-field :class="{'md-invalid': errors.lengthTextareaError}">
                            <label>Krótki opis problemu.</label>
                            <md-textarea v-model="feedback.message"></md-textarea>
                            <span class="md-error">Pole musi być wypełnione.</span>
                        </md-field>
                        <div class="mainSettings__version__feedbackForm__completeForm">
                            <div><md-button class="md-raised md-primary" @click="validateFeedback" v-show="!feedbackLoad">Wyślij</md-button></div>
                        </div>
                        <md-progress-bar class="md-accent" v-show="feedbackLoad" md-mode="indeterminate"></md-progress-bar>
                        
                    </div>
                <md-divider></md-divider>
            </div>
        </div>
                <div class="mainSettings__version">
            <md-toolbar :md-elevation="1">
                <span class="md-headline">Logs</span>
            </md-toolbar>
                

            <div class="mainSettings__version__info">
                <div class="mainSettings__version__info">
                    <span class="md-title">Logi</span>
                    <span class="subheading">Ostatnie zmiany w panelu admina.</span>
                </div>                

                <div style="display: flex; align-items: center; margin-bottom: 16px" v-if="componentInitLoad">
                    <div>
                        <md-field style="width: 20em; display: flex; margin: 0px">
                                <label>Szukaj...</label>
                                <md-input v-model="query"></md-input>
                        </md-field>
                        <span style="color: red; user-select: none;" v-if="errors.baselinker">Pole musi być wypełnione.</span>
                    </div>
                    <div>
                        <md-button @click.prevent="validateQuery()" class="md-raised md-primary">Szukaj</md-button>
                        <!-- <md-button @click.prevent="updateSellasistWithProducts()" class="md-raised md-primary">BULK UPDATE SELLASIST</md-button> -->
                    </div>
                </div>

                <div class="mainSettings__version__info__logs" v-if="this.query.length === 0">
                    <div class="mainSettings__version__info__patch__change" v-for="(log, index) in logs" :key="index">
                        <div class="mainSettings__version__info__patch__change__date">
                            <span class="md-caption">{{ log.createdAt }}</span>
                        </div>
                        <div class="mainSettings__version__info__patch__change__message">
                            <span class="md-body-1">{{ log.message }}</span>
                        </div>
                        <div class="mainSettings__version__info__patch__change__message">
                            <span class="md-body-1">
                                <router-link style="margin-left: 0.5rem; font-weight: bold; text-decoration: none" :to="`/backup/${log.backupId}`" target="_blank">
                                    {{ log.backupId }}
                                </router-link>
                            </span>
                        </div>
                    </div>
                <md-button class="md-primary" @click="loadMoreLogs" v-if="dataToLoad && !hideLoadMore && !queryLimitReached && componentInitLoad">Pobierz więcej</md-button>
                </div>
                <md-progress-bar class="md-accent" md-mode="indeterminate" v-if="!componentInitLoad"></md-progress-bar>
                <div class="mainSettings__version__info__logs" v-if="this.query.length > 0 && componentInitLoad">
                    <div class="mainSettings__version__info__patch__change" v-for="(log, index) in everything" :key="index">
                        <div class="mainSettings__version__info__patch__change__date">
                            <span class="md-caption">{{ log.createdAt }}</span>
                        </div>
                        <div class="mainSettings__version__info__patch__change__message">
                            <span class="md-body-1">{{ log.message }}</span>
                        </div>
                        <div class="mainSettings__version__info__patch__change__message">
                            <span class="md-body-1">
                                <router-link style="margin-left: 0.5rem; font-weight: bold; text-decoration: none" :to="`/backup/${log.backupId}`" target="_blank">
                                    {{ log.backupId }}
                                </router-link>
                            </span>
                        </div>
                    </div>
                </div>
            </div>

        </div>

        <md-progress-bar class="md-accent" md-mode="indeterminate" v-show="contentLoaded === false"></md-progress-bar>
        <md-snackbar :md-active.sync="showSnackbar" md-persistent>
        <span>Sukces! Wysłano wiadomość.</span>
        </md-snackbar>
    </div>
</div>
</template>

<script>
import loader from '../components/loader.vue';
import axios from 'axios';
import {
  db
} from '../configs/firebase'
import moment from 'moment';
import 'moment/locale/pl'
moment.locale('pl')

export default {
    components: {
        loader
    },
    data() {
        return {
            version: {
                versionCMS: "1.00",
                versionClient: "0.00",
            },
            feedback: {
                title: "",
                message: ""
            },
            query: "",
            loadMoreLoading: true,
            changelog: [],
            logs: [],
            logsLoaded: [],
            hideLoadMore: false,
            logTimestmap: [],
            contentLoaded: false,
            componentInitLoad: false,
            errors: {
                lengthError: false,// length error input
                lengthTextareaError: false,
                baselinker: false,
            },
            feedbackLoad: false,
            showSnackbar: false,
            dataToLoad: true,
            itemsToLoad: 15,
            queryLimitReached: false,
            everything: []
        }
    },
    props: ['user'],
    async created()
    {
        // Download changelog
        db.collection("Changelog").limit(5).orderBy("timestamp", "desc").get().then((snapshot)=>
        {
            for(let i=0;i < snapshot.docs.length; i++)
            {
                this.changelog.push(snapshot.docs[i].data());
                if(i === snapshot.docs.length-1)
                {
                    this.contentLoaded = true;
                }
            }
        }),
        this.getLogs()
    },
    methods: {
        async updateSellasistWithProducts()
        {
            try {

                let products = [];
                const downloadProducts = await db.collection("Products").get();
                downloadProducts.forEach(doc => {
                    if(doc.data().sellasistProductID == null)
                    {
                        products.push(doc.data());
                    }
                });

                console.log(products.length);
                let success = true;
                const chunksLength = 15;
                for(let i=0; i<products.length; i+=chunksLength)
                {
                    const chunk = products.slice(i, i+chunksLength);
                    try {
                        const result = await axios.post('https://europe-central2-kompreshop.cloudfunctions.net/webApi/updateSellasistWithProducts', { products: chunk })
                        console.log('Chunk processed successfully:', result.data);
                    } catch (error) 
                    {
                        console.error('Error processing chunk:', error);
                        success = false;
                        break;
                    }
                }
            } catch (error) 
            {
                console.log(error);
            }
        },
        async databaseSearch()
        {
            try {
                this.everything = [];
                this.componentInitLoad = false;
                const filterList = await db.collection('Logs').where("baselinkerID", "==", parseInt(this.query)).orderBy('createdAt', 'desc').get()
                filterList.forEach((doc) => {
                    let log = doc.data()
                    log.createdAt = moment.unix(log.createdAt.seconds).startOf().fromNow();
                    this.everything.push(log)
                });
                setTimeout(() => {
                    this.componentInitLoad = true;
                }, 1500);   
            } catch (error)
            {
                console.log('Wystąpił błąd:', error);
            }
        },
        buttonValidate()
        {
            let errorsAmount = 0;
            this.errors.baselinker = false;
            if(this.query.length === 0)
            {
                errorsAmount++;
                this.errors.baselinker = true;
            }

            return errorsAmount;
        },
        validateQuery()
        {
            this.errors.baselinker = false;
            if(this.buttonValidate() > 0) return;
            this.databaseSearch();    
        },
        getLogs()
        {
            this.componentInitLoad = false;
            db.collection("Logs").orderBy('createdAt', 'desc').limit(this.itemsToLoad)
            .onSnapshot((querySnapshot) => {
                this.logs = [];
                if(querySnapshot.docs.length<this.itemsToLoad)
                {
                    this.queryLimitReached = true;
                }
                else
                {
                    this.queryLimitReached = false;
                }
                querySnapshot.forEach((doc) => {
                    let log = doc.data()
                    log.createdAt = moment.unix(log.createdAt.seconds).startOf().fromNow();
                    this.logs.push(log);
                    this.lastLoaded = log.createdAt;
                });
            });
                    setTimeout(() => {
                this.componentInitLoad = true;
            }, 1000);
        },
        continueListening()
        {
            this.componentInitLoad = false;
            this.itemsToLoad = this.itemsToLoad + 15;
            this.dbListener = db.collection("Logs").orderBy('createdAt', 'desc').limit(this.itemsToLoad)
            .onSnapshot((querySnapshot) => {
                this.logs = [];
                if(querySnapshot.docs.length<this.itemsToLoad)
                {
                    this.queryLimitReached = true;
                }
                else
                {
                    this.queryLimitReached = false;
                }
                querySnapshot.forEach((doc) => {
                    let log = doc.data()
                    log.createdAt = moment.unix(log.createdAt.seconds).startOf().fromNow();
                    this.logs.push(log);
                    this.lastLoaded = log.createdAt;
                });
            });

        },
        loadMoreLogs()
        {
            this.getLogs();
            this.continueListening()
            setTimeout(() => {
                this.componentInitLoad = true;
            }, 500);
        },
        // validate feedback msg
        validateFeedback()
        {
            this.feedbackLoad = true;
            if(this.feedback.title.length>0)
            {
                this.errors.lengthError = false;
                if(this.feedback.message.length>0)
                {
                    this.errors.lengthTextareaError = false;
                    this.sendFeedback();
                }
                else
                {   
                    this.errors.lengthTextareaError = true;
                    this.feedbackLoad = false;
                }
            }
            else
            {
                this.errors.lengthError = true;
                this.feedbackLoad = false;
            }
        },
        // send feedback msg
        sendFeedback()
        {
            db.collection("Feedbacks").doc().set(this.feedback).then(()=>
            {
                this.feedback.title = "";
                this.feedback.message= "";
                this.feedbackLoad = false;
                this.showSnackbar = true;
            })
        },
    }
}
</script>
<style lang="scss">
@import '../styles/variables';

    .search
    {
        height: auto;
        margin-top: 10.527em;



        &__content
        {
            display: flex;
            flex-direction: column;
            width: 90%;
            padding-left: 5%;
            padding-right: 5%;

            &__results
            {
                display: flex;
                width: 100%;
                flex-direction: column;
                align-items: center;
                margin-top: 1rem;
                margin-bottom: 1rem;

                &__text
                {
                    margin-top: 1rem;
                    user-select: none;
                    color: $gray;
                }

                &__row
                {
                    display: flex;
                    margin-top: 1.25rem;
                    margin-bottom: 1.25rem;
                    justify-content: space-evenly;
                    width: 100%;
                }

            }

            &__texts
            {
                width: 100%;
                margin-bottom: 1rem;
                h1 {
                    text-align: left;
                    user-select: none;
                    margin: 0;
                    padding: 0;
                    font-family: 'Poppins', sans-serif !important;
                    font-weight: 500;
                    font-size: 1.25rem;
                }
                h3
                {
                    text-align: left;
                    font-weight: 400;
                    padding: 0;
                    font-size: 1rem;
                    margin: 0;
                }
            }

            &__input
            {
                display: flex;
                width: 100%;
                justify-content: center;
                align-items: center;
                margin-top: 1rem;
                margin-bottom: 1rem;
                box-sizing: border-box;

                &__getQuery
                {
                    display: flex;
                    align-items: center;

                    input
                    {
                        -webkit-appearance: none;
                        -moz-appearance: none;
                        font-weight: 400;
                        text-indent: 1px;
                        text-overflow: '';
                        font-size: 1rem;
                        z-index: 2;
                        border: none;
                        background: none;
                        font-size: 1.25rem;
                        outline: none;
                        color: $gray;
                        transition: all 0.4s ease-in-out;
                        padding-bottom: 0.64rem;
                        padding-top: 0.64rem;
                        padding-left: 2.441rem;
                        padding-right: 2.441rem;
                        border-bottom: 1px solid #dddddd;
                        opacity: 0.7rem;
                        transition: all 0.4s ease-in;
                    }

                    input::placeholder
                    {
                        color: $gray;
                        opacity: 0.4;
                        font-size: 1rem;
                    }

                    input[type="text"]:focus
                    {
                        
                        box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
                        opacity: 1;
                        padding-top: 1rem;
                        padding-bottom: 1rem;
                        padding-left: 3.052rem;
                        padding-right: 3.052rem;
                    }

                    button
                    {
                        margin-left: 1rem;
                        border: 2px solid $red;
                        color: $red;
                        font-size: 1rem;
                        background: none;
                        padding-left: 1rem;
                        padding-right: 1rem;
                        padding-top: 0.64rem;
                        padding-bottom: 0.64rem;
                        border-radius: 5px;
                        transition: all 0.4s ease-in-out;
                    }

                    button:hover
                    {
                        cursor: pointer;
                        border: 2px solid $redHover;
                        color: $redHover;
                        border-radius: 10px;
                        transform: translateY(-1px);
                        box-shadow: 0 1px 3px rgba(0,0,0,0.24), 0 1px 2px rgba(0,0,0,0.24);
                    }



                    &__icon
                    {
                        position: relative;
                        left: 25px;
                        z-index: 1;
                        color: $black;
                    }

                }

            }
        }
    }


.mainSettings
{
    &__version
    {
        margin-top: 1em;
        margin-bottom: 1em;

        &__feedbackForm
        {
            margin-top: 2em;
            width: 100%;
            display: flex;
            justify-content: center;
            flex-direction: column;

            &__completeForm
            {
                display: flex;
                justify-content: flex-end;
                align-items: center;
            }
        }

        &__info
        {
            margin-top: 1em;
            margin-bottom: 1em;
            display: flex;
            flex-direction: column;

            &__logs
            {
                margin-top: 0.5em;
                margin-bottom: 2em;
                display: flex;
                flex-direction: column;
            }

            &__patch
            {
                margin-top: 2em;
                margin-bottom: 2em;
                display: flex;
                flex-direction: column;

                &__change
                {
                    display: flex;
                    margin-top: 0.2em;
                    &__date
                    {
                        margin-right: 0.5em;
                    }
                }
            }
        }
    }
}

</style>

