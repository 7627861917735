<template>
    <div class="dropdown">
        <div class="dropdown__namer">{{name}}</div>
        <div class="dropdown__selector">
            <div class="dropdown__selector__icon">
                <i :class="classList" id="mainIcon"></i>
            </div>
            <div class="dropdown__selector__select">
                <select v-model="value" @change="emitChange" :class="{valueNotEmpty: value.length>0 && value !== 'loading'}">
                    <option value="loading" disabled selected hidden>Wyszukiwanie..</option>
                    <option v-for="option in options" :value="option.value" :key="option.value">
                        {{option.name}}
                    </option>
                </select>
            </div>
            <div class="dropdown__selector__arrow">
                <i :class="{icon: true, 'fi-rr-angle-down': true}" id="mainIcon"></i>
            </div>
        </div>
    </div>
</template>
<script>


    export default {
    data() {
        return {
            classList: {
                'fi-rr-settings-sliders': false,
                'icon': true
            },
            value: "loading"
        }
    },
    watch: {
        'resetInput'(val)
        {
            if(val === true)
            {
                this.value = "";
            }
        },
        'options'(val)
        {
            if(val.length>0)
            {
                this.value = "";
            }
        }
    },
    methods: {
        emitChange()
        {
            let data = {
                name: this.selectName,
                value: this.value
            }
            this.$emit('selectChange', data);
        }

    },
    created()
    {
        if(this.icon !==undefined)
        {
            this.classList[this.icon] = true;
        }
    },
    mounted()
    {
        if(this.options.length>0)
        {
            this.value = this.options[0].value;
        }
    },
    props: ['options','icon','selectName','name','resetInput']
    }
</script>
<style lang="scss" scoped>
@import '../styles/variables';

.icon
{
    display: block;
}

select::-ms-expand {
    display: none;
}

.dropdown
{
    display: flex;
    flex-direction: column;

    &__namer
    {
        width: 100%;
        font-size: 0.8rem;
        font-weight: 400;
        padding-left: 1rem;
    }

    &__selector
    {
        opacity: 0.9;
        width: 100%;
        font-family: inherit;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: all 0.4s ease-in;


        &__arrow
        {
            display: flex;
            position: relative;
            right: 20px;
            z-index: 1;
            color: $black;
        }
        &__icon
        {
            position: relative;
            left: 20px;
            z-index: 1;
            color: $black;
        }

        &__select
        {
            display: flex;
            flex-direction: column;
            width: 100%;
            z-index: 2;

            select
            {
                -webkit-appearance: none;
                -moz-appearance: none;
                text-indent: 1px;
                text-overflow: '';
                z-index: 2;
                border: none;
                background: none;
                font-size: 0.8rem;
                outline: none;
                color: $gray;
                transition: all 0.4s ease-in-out;
                padding-bottom: 0.64rem;
                padding-top: 0.64rem;
                padding-left: 1.953rem;
                padding-right: 1.953rem;
                border-bottom: 1px solid #dddddd;
            }

            select:hover
            {
                color: $black;
                border-bottom: 1px solid $gray;
                cursor: pointer;    
            }
      
        }
    }

    &__selector:hover
    {
        color: $black;
    }
}


@media (max-width: 770px)
{
    .dropdown
    {
        width: 100%;
    }
    .dropdown__selector
    {

        &__select
        {
            select
            {
                width: 100%;
            }
        }
    }
}

.valueNotEmpty
{
    border-bottom: 1px solid $red !important;
    color: black !important;
}

.valueNotEmpty:hover
{
    opacity: 0.6;
}

</style>