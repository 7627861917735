<template>
    <div class="endOrder">
        <navbar></navbar>
        <loader v-if="loading"></loader>
        <div v-if="!loading" class="endOrder__content">
            <div class="endOrder__content__message">
                <div class="endOrder__content__message__header">
                    {{header}}
                </div>
                <div class="endOrder__content__message__subtext">
                    {{subtext}}
                    <div class="endOrder__content__message__subtext__transfer" v-if="this.$route.query.type === 'transfer'">
                        <div><span>Tytuł przelewu: </span><span class="endOrder__content__message__subtext__transfer__content">{{title}}</span></div>
                        <div><span>Kwota: </span><span class="endOrder__content__message__subtext__transfer__content">{{price}} PLN</span></div>
                        <div><span>Konto bankowe: </span><span class="endOrder__content__message__subtext__transfer__content">{{bankAccount}}</span></div>
                        <div><span>Adres: </span><span class="endOrder__content__message__subtext__transfer__content">{{address}}</span></div>
                    </div>
                </div>
                <div class="endOrder__content__message__image">
                    <img loading="lazy" :src="require('../assets/' + imgSource)" alt="Sklep komputerowy ze sprzętem poleasingowym. Kupuj laptopy uzywane oraz tanie komputery pc z outletu komputerowego.">
                </div>
                <div class="endOrder__content__message__goBack">
                    <mainButton :cta="'Powrót'" @clicked="goBack()"></mainButton>
                </div>
            </div>
        </div>
        <trust></trust>
        <ftr></ftr>
    </div>
</template>
<script>
// Imports
    import {db} from '../configs/firebase';
    import loader from "../components/loader.vue";
    import navbar from "../components/navbarStore.vue";
    import trust from "../components/trust.vue";
    import ftr from "../components/footer.vue";
    import mainButton from "../components/mainButton.vue";
    import suggestedProducts from "../components/suggestedProducts.vue";


export default {
    data() {
      return {
        loading: true,
        header: "Dziękujemy",
        subtext: "Transakcja przebiegła pomyślnie.",
        title: "Tytuł przelewu:",
        bankAccount: "89 1140 1993 0000 3476 1600 1001 (mBank)",
        address: "Kompre sp z o.o., Rogaszyce, ul. Wesoła 37, 63-500 Ostrzeszów",
        imgSource: "thanks-min.png",
        price: 0
      }
    },
    methods: {
        goBack()
        {
            this.$router.push({ path: '/' });
        },
        async prepare()
        {
            if(this.$route.query.error === "501")
            {
                this.header = "Transakcja odrzucona.";
                this.subtext = "Coś poszło nie tak w trakcie dokonywania płatności. Spróbuj ponownie.";
                this.imgSource = "deny-min.png";
                this.loading = false
            }
            else if(this.$route.query.type === "transfer")
            {
                const queryResult = await db.collection("Orders").doc(this.$route.query.id).get();
                let data = queryResult.data();
                this.header = "Ostatnia prosta!";
                this.subtext = "Aby dokończyć składanie zamówienia opłać swoje zamówienie:";
                this.title = data.baselinkerID;
                this.price = data.totalPrice.toFixed(2);
                this.imgSource = "transfer_endOrder.svg";
                this.loading = false
            }
            else if(this.$route.query.info === "message")
            {
                this.header = "Dziękujemy";
                this.subtext = "Twoja wiadomość została wysłana. Niedługo odezwiemy się do Ciebie.";
                this.imgSource = "chat-min.png";
                this.loading = false
            }
            else{
                this.loading = false
            }
        }
    },
    created()
    {
      window.scrollTo(0,0);
      this.prepare();
    },
    components: {navbar,trust,ftr,loader,mainButton,suggestedProducts},
}
</script>
<style lang="scss" scoped>
    @import '../styles/variables';

    .endOrder
    {
        display: flex;
        flex-direction: column;
        width: 100vw;
        height: auto;

        &__content
        {
            display: flex;
            width: 90%;
            padding-left: 5%;
            padding-right: 5%;
            margin-top: 10.527em;

            &__message
            {
                margin-top: 1.563rem;
                margin-bottom: 1.563rem;
                width: 100%;
                display: flex;
                flex-direction: column;
                text-align: center;
                align-items: center;
                justify-content: center;
                user-select: none;

                &__header
                {
                    font-weight: 500;
                    font-size: 1.563rem;
                }

                &__subtext
                {
                    font-size: 1.25rem;
                    margin-bottom: 1rem;
                    &__transfer{
                        margin-top: 1rem;
                        font-size: 1rem;
                        display: flex;
                        flex-direction: column;
                        span{
                            font-weight: 500;
                        }
                        &__content{
                            font-weight: 400 !important;
                            user-select: text;
                        }
                    }
                }

                &__image
                {
                    margin-top: 1.25rem;
                    margin-bottom: 1.25rem;
                    width: 100%;

                    img
                    {
                        opacity: 0.9;
                        object-fit: contain;
                        max-width: 30%;
                        height: auto;
                    }
                    
                }

                &__goBack
                {
                    display: flex;
                    width: 40%;
                    margin-bottom: 1.25rem;
                }
            }
        }
    }

    @media (max-width: 1024px)
    {
        .endOrder__content__message__goBack
        {
            width: 80%;
        }

    }
    @media (max-width: 769px) {
    .endOrder__content{
        margin-top: 4.768em;
    }
  }


</style>