<template>
    <div class="photos">
        <div class="photos__content">
            <div class="photos__content__header" v-if="step !== 3">
                <h1><span>K</span> - jak Konkretne zdjęcia</h1>
                <h4>Laptop na prezent, dla dziewczyny, a może dla Ciebie?</h4>
                <p>Chcemy być w 100% pewni, że sprzęt który otrzymasz spełni wszystkie Twoje wymagania. Szanujemy Twój czas i przed zakupem możemy wysłać Ci zdjęcia w wysokiej rozdzielczości, żebyś na ich podstawie przekonał się, że w Kompre dbamy o jakość sprzętu! Konkretną sztukę laptopa ze zdjęć rezerwujemy tylko na okres 48 godzin.</p>
            </div>
            <transition name="fade">
            <div class="photos__content__form" v-if="step === 1">

                    <div class="photos__content__form__header">
                        <h4>Uzyskaj zdjęcia sprzętu przed wysyłką</h4>
                        <span>W przypadku dużej liczby zapytań, czas odpowiedzi to kilka dni roboczych.</span>
                    </div>


                    <label class="photos__content__form__label">Link do produktu:</label>
                    <input class="photos__content__form__input" type="text" placeholder="np. https://kompre.pl/produkt/aSZbVEH9FnNaVuBUu4X5" v-model="form.productLink">
                    <div v-if="form_errors.productLink" class="photos__content__form__error"> Wstaw link do oferty. </div> 

                    <label class="photos__content__form__label">Dodatkowe informacje:</label>
                    <input class="photos__content__form__input" type="text" placeholder="np. Proszę o wstępną rezerwacje" v-model="form.additionalInfo">

                    <div class="photos__content__form__button" v-if="!loading">
                        <div @click="errorCheck()" class="photos__content__form__button__bttn">
                            <bttn :cta="'Dalej'"></bttn>
                        </div>
                    </div>

            </div>
            </transition>

            <transition name="fade">
            <div class="photos__content__form" v-if="step === 2">

                    <div class="photos__content__form__header">
                        <h4>Uzyskaj zdjęcia sprzętu przed wysyłką</h4>
                        <span>Uzupełnij pola formularza, aby kontynuować.</span>
                    </div>


                    <label class="photos__content__form__label">Dane osobowe:</label>
                    <input class="photos__content__form__input" type="text" placeholder="np. Tomasz Kowalski" v-model="form.name">
                    <div v-if="form_errors.name" class="photos__content__form__error"> Uzupełnij swoje dane. </div> 

                    <label class="photos__content__form__label">E-mail:</label>
                    <input class="photos__content__form__input" type="text" placeholder="np. przykład@gmail.com" v-model="form.email">
                    <div v-if="form_errors.email" class="photos__content__form__error"> Uzupełnij swój e-mail. </div> 

                    <label class="photos__content__form__label">Numer telefonu:</label>
                    <input class="photos__content__form__input" type="text" placeholder="np. 724 463 729" v-model="form.telNum">
                    <div v-if="form_errors.telNum" class="photos__content__form__error"> Uzupełnij swój numer telefonu. </div> 

                    <div class="photos__content__form__button" v-if="!loading">
                         <div class="photos__content__form__button__bttnBackward">
                            <bttn @clicked="changeStep(1)" :cta="'Cofnij'"></bttn>
                        </div>
                        <div @click="errorCheck()" class="photos__content__form__button__bttnForward">
                            <bttn :cta="'Wyślij'"></bttn>
                        </div>
                    </div>

            </div>
            </transition>

            <transition name="fade">        
            <div class="photos__content__form margin" v-if="step === 3">

                    <div class="photos__content__form__header">
                        <h4>Gratulacje, udało Ci się wysłać prośbę o zdjęcie produktu.</h4>
                    </div>

                    <div class="photos__content__form__button">
                        <div class="photos__content__form__button__bttnSend">
                            <bttn @clicked="mainSite()" :cta="'Wróć do strony głównej'"></bttn>
                        </div>
                    </div>
            </div>   
    </transition>  
        </div>
        <navbar/>
        <trust/>
        <ftr/>
    </div>
</template>
<script>
    // Imports
    import navbar from "../components/navbarStore.vue";
    import trust from "../components/trust.vue";
    import ftr from "../components/footer.vue";
    import bttn from "../components/mainButton.vue";
    import axios from 'axios';

    export default {
        data() {
            return {
                step: 1,
                loading: false,
                form:{
                    productLink: '',
                    additionalInfo: '',
                    name: '',
                    email: '',
                    telNum: ''
                },
                form_errors:{
                    productLink: false,
                    name: false,
                    email: false,
                    telNum: false
                },
            }
        },
        methods:
        {
            async orderPhoto(){
                await axios.post('https://europe-central2-kompreshop.cloudfunctions.net/webApi/photoFormMail', this.form)
                this.loading = false;
                this.changeStep(3)
            },
            mainSite(){
                this.$router.push("/");
            },
            changeStep(n){
                this.step = 0;
                setTimeout(()=>{
                this.step = n;
                },500)
            },
             errorCheck(){
                 if(this.step === 1){
                     if(/^([a-z][a-z0-9\*\-\.]*):\/\/(?:(?:(?:[\w\.\-\+!$&'\(\)*\+,;=]|%[0-9a-f]{2})+:)*(?:[\w\.\-\+%!$&'\(\)*\+,;=]|%[0-9a-f]{2})+@)?(?:(?:[a-z0-9\-\.]|%[0-9a-f]{2})+|(?:\[(?:[0-9a-f]{0,4}:)*(?:[0-9a-f]{0,4})\]))(?::[0-9]+)?(?:[\/|\?](?:[\w#!:\.\?\+=&@!$'~*,;\/\(\)\[\]\-]|%[0-9a-f]{2})*)?$/.test(this.form.productLink))
                    {
                        this.form_errors.productLink = false;
                        this.changeStep(2);
                    }
                    else
                        {
                            this.form_errors.productLink = true
                            return 0;
                        }
                 }
                 if(this.step === 2){
                    if(this.form.name.length > 0 )
                        {
                            this.form_errors.name = false;
                            if(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(this.form.email))
                                {
                                    this.form_errors.email = false;
                                    if(/^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/.test(this.form.telNum))
                                        {
                                            this.form_errors.telNum = false;
                                            this.loading = true;
                                            this.orderPhoto();
                                        }
                                    else
                                        {
                                            this.form_errors.telNum = true
                                            return 0;
                                        }
                                }
                            else
                                {
                                    this.form_errors.email = true
                                    return 0;
                                }
                        }
                    else
                        {
                            this.form_errors.name = true
                            return 0;
                        }
                 }
             }
        },
        mounted()
        {
            window.scrollTo(0,0);
        },
        components: {navbar,trust,ftr,bttn},
    }
</script>
<style lang="scss" scoped>
    @import '../styles/variables';

.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

    .photos
    {
        width: 100vw;
        height: auto;
        margin-top: 10.527em;
        &__content
        {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: 90%;
            padding-left: 5%;
            padding-right: 5%;
            &__header{
                margin-top: 1.563rem;
                margin-bottom: 1rem;
                width: 55%;
                h1{
                    font-size: 1.563rem;
                    font-weight: 600;
                    text-align: center;
                    padding: 0;
                    margin: 0;
                    margin-bottom: 0.41rem;
                    span{
                        font-size: 1.953rem !important;
                        color: #f01827;
                    }
                }
                p{
                    font-size: 1rem;
                    margin: 0;
                    padding: 0;
                    text-align: center;
                }
                h4{
                    margin: 0;
                    padding: 0;
                    text-align: center;
                    font-size: 1rem !important;
                    font-weight: 500;
                    margin-bottom: 0.21rem;
                }
            }
            &__form
            {
                display: flex;
                flex-direction: column;
                justify-content: center;
                max-width: 40%;
                border: 1px solid $border;
                text-align: left;
                padding: 2rem;
                border-radius: 0.75rem;
                -webkit-box-sizing: border-box;
                -moz-box-sizing: border-box;
                box-sizing: border-box;
                margin-bottom: 1rem;
                &__header
                {
                    width: 100%;
                    display: flex;
                    justify-content: center;
                    flex-direction: column;
                    margin: 0;
                    padding: 0;
                        h4
                        {
                            text-align: center;
                            font-weight: 500;
                            font-size: 1.25rem;
                            margin: 0;
                            padding: 0;
                        }
                        span{
                            text-align: center;
                            font-size: 0.8rem;
                            margin: 0;
                            margin-bottom: 0.21rem;
                            padding: 0;
                        }
                }
                &__label
                {
                    color: $gray;
                    display:block;
                    margin-bottom: 0.41rem;
                    margin-top: 0.8rem;
                    font-size: 0.64rem;
                    font-weight: 500;
                    text-transform: uppercase;
                    letter-spacing: 1px;
                    font-weight: bold;
                }
                
                &__input
                {
                    display: inline-block;
                    padding: 10px 6px;
                    width:100%;
                    box-sizing: border-box;
                    border: none;
                    border-bottom: 1px solid $connectorColor;
                    color: #555;
                    border-radius: 0;
                }
                input::placeholder{
                    color: #bdbdbd;
                }

                &__button
                {
                    display: flex;
                    width: 100%;
                    justify-content: center;
                    align-items: center;
                    margin-top: 1REM; 
                    &__bttn 
                    {
                    display: flex;
                    flex-direction: row;
                    width: 30%;
                    }
                    &__bttnForward
                    {
                    display: flex;
                    flex-direction: row;
                    min-width: 30%;
                    margin-left: 1rem;
                    }
                    &__bttnBackward
                    {
                    display: flex;
                    flex-direction: row;
                    min-width: 30%;
                    margin-right: 1rem;
                    }
                    &__bttnSend
                    {
                    display: flex;
                    flex-direction: column;
                    width: 100%;
                    }
                }
                &__error
                {
                    color: $red;
                    margin-top: 0.21rem;
                    margin-left: 0.41rem;
                    font-size: 0.64rem;
                    font-weight: 500;
                }
            }
        }
    }

    .margin{
        margin-top: 7.451rem;
        margin-bottom: 7.451rem;
    }

    @media (max-width: 1024px)
    {
        .photos__content{
            &__header{
                width: 80%;
            }
            &__form{
                max-width: 70%;
            }
        }
    }
    @media (max-width: 769px) {
    .photos{
        margin-top: 4.768rem !important;
        &__content{
            &__header{
                width: 100%;
            }
            &__form{
                max-width: 100%;
            }
        }
    }
}

    @media (max-width: 370px) {
    .photos{
        &__content{
            &__header{
                margin-top: 1rem;
                margin-bottom: 1rem;
                h1{
                    font-size: 1.25rem !important;
                }
            }
        }
    }
}

</style>