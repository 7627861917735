<template>
    <div class="emptyState">
        <div class="emptyState__header">
            Koszyk Pusty
        </div>
        <div class="emptyState__subtext">
            Dodaj produkt do koszyka, aby kontynuować zakupy.
        </div>
        <div class="emptyState__image">
            <img src="../assets/emptyStateCart.png" alt="Tanie komputery oraz uzywany laptop w niskiej cenie. Monitory poleasingowe. Sprzęt dla graczy w sklep komputerowy Kompre."> 
        </div>
    </div>
</template>
<script>
    export default {
        data() {
            return {
                
            }
        },
        methods: {
        },
        beforeCreate()
        {

        }
    }
</script>
<style lang="scss" scoped>
    // Color Variables Import
    @import '../styles/variables';

    .emptyState
    {
        width: 100vw;
        height: 85vh;
        display: flex;
        text-align: center;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        user-select: none;

        &__header
        {
            font-size: 1.563rem;
            font-weight: 500;
            opacity: 0.5;
        }

        &__subtext
        {
            font-size: 1.25rem;
            opacity: 0.5;
        }

        &__image
        {
            margin-top: 1em;
            opacity: 0.3;

            img
            {
                width: 80%;
                height: auto;
            }
        }
    }


</style>