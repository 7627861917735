import Vue from 'vue';
import VueRouter from 'vue-router';
Vue.use(VueRouter)



function lazyLoad(view){
  return() => import(`@/views/${view}.vue`)
}

const routes = [
  {
    path: '/admin',
    name: 'Admin',
    component: lazyLoad('Admin'),
    meta: {title: 'Panel Administratora', description: "Panel administratorski Kompre.pl", keywords: "komputery poleasingowe,laptopy uzywane,laptopy poleasingowe" }
  },
  {
    path: '/szukaj',
    name: 'Search',
    component: lazyLoad('Search'),
    meta: {title: 'Szukaj tanie laptopy oraz komputery poleasingowe | Kompre.pl', description: "Znajdź najtańsze komputery poleasingowe, laptopy używane, tanie monitory z zaufanego źródło. Sprawdź ofertę sklepu komputerowego Kompre.", keywords: "apple macbook,laptop lenovo,laptop dell,komputery gamingowe,laptopy poleasingowe,laptop poleasingowy,laptop uzywany, tani laptop do biura, najtansze monitory,tani monitor"}
  },
  {
    path: '/',
    name: 'Homepage',
    component: lazyLoad('Home'),
    meta: {title: 'Tanie laptopy używane oraz komputery poleasingowe | Kompre.pl', description: "Największy wybór tanich komputerów poleasingowych. Kupuj najtańsze laptopy używane, komputery poleasingowe oraz tanie monitory używane cenionych producentów w najniższych cenach. Sprawdź sam!", keywords: "laptop poleasingowy,tani laptop,laptop do 1000,tani komputer,komputer stacjonarny,komputery poleasingowe,outlet poleasingowy,tanie monitory do komputera,monitory komputerowe,czesci do laptopa,czesci komputerowe,sklep komputerowy,outlet poleasingowy,outlet komputerowy,komputer dell,komputer lenovo,laptop dell,laptop hp,laptop lenovo"}
  },
  {
    path: '/ordercheck/:id',
    name: 'Order',
    component: lazyLoad('Order'),
    meta: {title: 'Outlet Komputerów Poleasingowych | Kompre.pl', description: "Największy wybór tanich komputerów poleasingowych. Kupuj najtańsze laptopy używane, komputery poleasingowe oraz tanie monitory używane cenionych producentów w najniższych cenach. Sprawdź sam!", keywords: "sklep komputerowy wroclaw,sklep komputerowy warszawa,tanie laptopy,laptopy uzywane,komputer dell,komputer hp,dell precision"}
  },
  {
    path: '/backup/:id',
    name: 'Backup',
    component: lazyLoad('Backup'),
    meta: {title: 'Outlet Komputerów Poleasingowych | Kompre.pl', description: "Największy wybór tanich komputerów poleasingowych. Kupuj najtańsze laptopy używane, komputery poleasingowe oraz tanie monitory używane cenionych producentów w najniższych cenach. Sprawdź sam!", keywords: "sklep komputerowy wroclaw,sklep komputerowy warszawa,tanie laptopy,laptopy uzywane,komputer dell,komputer hp,dell precision"}
  },
  {
    path: '/kategorie/:category',
    name: 'Category',
    component: lazyLoad('Category'),
    meta: {title: 'Najtańsze komputery używane oraz laptopy poleasingowe | Kompre.pl', description: "Najtańsze komputery poleasingowe, laptopy używane oraz tanie monitory cenionych marek takie jak Lenovo, Dell oraz HP. Sprawdź tanie laptopy poleasingowe w dobrej cenie.", keywords: "tani laptop poleasingowy,dobry komputer uzywany,najtanszy laptop poleasingowy,komputery poleasingowe wrocław,laptop lenovo,laptop dell,laptop hp,komputer stacjonarny,monitor do komputera,sklep komputerowy,all in one,kompre,najtansze monitory,tani monitor"}
  },
  {
    path: '/produkt/:id',
    name: 'Product',
    component: lazyLoad('Product'),
    meta: {title: 'Outlet Komputerów Poleasingowych | Kompre.pl', description: "Najtańsze monitory, komputery stacjonarne oraz tanie laptopy używane cenionych producentów. Sprawdź najtańszy sklep komputerowy." , keywords: "komputer za 1000 zł,komputer do 1000 zł,komputer pc,laptop poleasingowy,komputery poleasingowe,tani laptop,dobry laptop,laptop poleasingowy,komputer stacjonarny" }
  },
  {
    path: '/politykaprywatnosci',
    name: 'Privacy',
    component: lazyLoad('Privacy'),
    meta: {title: 'Polityka Prywatności | Kompre.pl', description: "Znajdź swój dobry laptop używany, tani komputer poleasingowy oraz najtańszy monitor lcd w sklepie komputerowym Kompre!", keywords: "fajny komputer,laptop ceny,laptop dla seniora,laptop do pracy biurowej,laptop tani,laptop 8gb ram,matryca laptop,laptop z podświetlaną klawiaturą,laptop na studia,laptop poleasingowy,najtansze laptopy,tani komputer stacjonarny,apple macbook,laptop dell,laptop hp,komputer aio"}
  },
  {
    path: '/regulamin',
    name: 'Terms',
    component: lazyLoad('Terms'),
    meta: {title: 'Regulamin sklepu | Kompre.pl', description: "", keywords: "laptop poleasingowy,laptopy poleasingowe,tani komputer,komputer uzywany,outlet komputerowy,sklep komputerowy wroclaw,komputer stacjonarny cena,stary laptop,laptop jaki wybrać,maly laptop,laptop do pracy zdalnej,bateria laptop,matryca laptop,laptop do 1000,jaki laptop do 2500"}
  },
  {
    path: '/koszyk',
    name: 'Cart',
    component: lazyLoad('Cart'),
    meta: {title: 'Koszyk zakupowy | Kompre.pl', description: "Szukasz taniego laptopa do pracy? Sprawdź outlet komputerów poleasingowych oraz tanich laptopów. Najwydajniejsze używane komputery, laptopy używane oraz najtańsze monitory renomowanych producentów.", keywords: "tani laptop,laptop z outletu,najtansze laptopy uzywane,tani komputer poleasingowy,tanie komputery dell,komputery all in one,komputer aio,smartphone,apple iphone,tablety uzywane,monitor uzywany,monitory komputerowe"}
  },
  {
    path: '/zamowienie',
    name: 'Finalize',
    component: lazyLoad('Finalize'),
    meta: {title: 'Finalizuj zakupy | Kompre.pl', description: "Sprawdź promocję na tanie laptopy, komputery używane oraz monitory w wyjątkowych cenach! Odwiedź outlet komputerowy Kompre!", keywords: "tanie laptopy,laptop apple,komputery dell,tanie komputery poleasingowe,komputer poleasingowy,laptop poleasingowy,najtansze laptopy,uzywane laptopy,uzywane telefony"}
  },
  {
    path: '/podziekowanie',
    name: 'endOrder',
    component: lazyLoad('endOrder'),
    meta: {title: 'Zakończenie transakcji | Kompre.pl', description: "Sprawdź najtańsze komputery premium. Tanie laptopy poleasingowe oraz najtańsze monitory. Sklep komputerowy Kompre", keywords: "kompre,tanie komputery,najtansze laptopy uzywane,dobry komputer,laptop gamingowy,czesci do komputera,czesci do laptopa"}
  },
  {
    path: '/reklamacje',
    name: 'Complaint',
    component: lazyLoad('Complaint'),
    meta: {title: 'Złóż reklamcje | Kompre.pl', description: "Znajdź najtańsze monitory używane, laptop poleasingowy, tani komputer stacjonarny. Tanie laptopy w zasięgu Twojej ręki.", keywords: "kompre,kompre reklamacja,najtanszy laptop,tanie laptopy,tanie monitory uzywane,monitory do komputera,komputery gamingowe,dobry laptop,tani laptop,komputery premium"}
  },
  {
    path: '/kontakt',
    name: 'Contact',
    component: lazyLoad('Contact'),
    meta: {title: 'Skontaktuj się z nami | Kompre.pl', description: "Tani laptop poleasingowy w zasięgu Twojej ręki. Sprawdź najtańsze komputery oraz monitory używane ze sklepu komputerowego Kompre.", keywords: "kompre,kompre kontakt,sklep komputerowy wroclaw,sklep komputerowy ostrzeszow,tanie komputery uzywane,tani komputer,najtansze komputery,tani monitor,tanie monitory,tani laptop, laptop lenovo,laptop asus,hp,"}
  },
  {
    path: '/vat0',
    name: 'Vat0',
    component: lazyLoad('Vat0'),
    meta: {title: 'Komputery bez VAT | Kompre.pl', description: "Tani sprzęt komputerowy bez VAT dla instytucji oraz organizacji. Poleasingowe oraz używane sprzęty od sklepu komputerowego Kompre", keywords: "kompre, kompre bez vat, vat 0%, komputer poleasingowy"}
  },
  {
    path: '/zdjecia',
    name: 'Photos',
    component: lazyLoad('Photos'),
    meta: {title: 'Zdjęcia przed wysyłką | Kompre.pl', description: "tba", keywords: "tba"}
  },
  {
    path: '/bezpieczenstwo',
    name: 'safetyPurchase',
    component: lazyLoad('safetyPurchase'),
    meta: {title: 'Bezpieczne zakupy | Kompre.pl', description: "tba", keywords: "tba"}
  },
  {
    path: '/promocje',
    name: 'Sales',
    component: lazyLoad('Sales'),
    meta: {title: 'Promocje | Kompre.pl', description: "tba", keywords: "tba"}
  },
  {
    path: '/xml',
    name: 'Xml',
    component: lazyLoad('Xml'),
    meta: {title: 'XML | Kompre.pl', description: "tba", keywords: "tba"}
  },
  {
    path: '/promocje/:sales',
    name: 'productSales',
    component: lazyLoad('productSales'),
    meta: {title: 'Promocje | Kompre.pl', description: "tba", keywords: "tba"}
  },
]
const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
