<template>
    <div class="trusted" >
        <div class="trusted__words">
            <span class="red"> Kompre. <span class="gray">Ufamy tylko najlepszym.</span></span>
        </div>
        <div class="trusted__container">
            <div class="trusted__container__image">
                <img src="../assets/companys/fujitsu.png" alt="Komputery poleasingowe i laptopy używane najtaniej. Odbiór we Wrocław.">
            </div>
            <div class="trusted__container__image">
                <img src="../assets/companys/apple.png" alt="Laptopy poleasingowe i komputery poleasingowe w sklep komputerowy.">
            </div>
            <div class="trusted__container__image">
                <img src="../assets/companys/dell.png" alt="Najtańsze monitory używane. Sprawdź outlet poleasingowy z Wrocławia.">
            </div>
            <div class="trusted__container__image">
                <img class="mobile-hp" src="../assets/companys/hp.png" alt="Najtańsze laptopy uzywane. Kup nowy komputer oraz tanie monitory.">
            </div>
            <div class="trusted__container__image">
                <img src="../assets/companys/lenovo.png" alt="Tani laptop używany, komputery dla szkół, sprzęt IT.">
            </div>
            <div class="trusted__container__image">
                <img src="../assets/companys/aoc.png" alt="Nowy laptop oraz uzywany. Sklep komputerowy ze sprzętem poleasingowym.">
            </div>
            <div class="trusted__container__image">
                <img src="../assets/companys/lg.png" alt="Monitory poleasingowe, tanie laptopy oraz komputery w ofercie outletu poleasingowego.">
            </div>
        </div>
    </div>
</template>

<script>


export default {
  data() {
    return {
    }
  },
  methods: {

  },
  components: {},
}
</script>
<style lang="scss" scoped>
// Import variables
@import '../styles/variables';

.trusted
{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    width: 100vw;
    height: auto;

    &__words
    {
        font-weight: 500;
        user-select: none;
        width: 100%;
        text-align: center;
    }

    &__container
    {
        margin-top: 1.25rem;
        margin-bottom: 1.25rem;
        width: 90%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        
    }
}

.red
{
    color: $red;
}

.gray
{
    color: $gray;
}

@media (max-width: 1025px)
{
    .trusted
    {
        &__container
        {
            // flex-direction: column;

            &__image
            {
                width: 12%;
                display: flex;
                align-items: center;
                justify-content: center;
                img{
                    max-height: 100%;
                    max-width: 100%;
                }
            }
            // &__image:last-child{
            //     justify-content: flex-end;
            // }
        }
    }
    .mobile-hp{
        max-height: 70% !important;
        max-width: 70% !important;
    }
}

</style>