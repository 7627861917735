<template>
  <div class="blog">
    <div class="blog__header"><span class="md-display-1">Zarządzaj blogiem</span></div>
    <div class="blog__menu"><md-switch v-model="newPost">Tryb tworzenia</md-switch></div>
    <div class="blog__postList" v-if="newPost === false">
      <md-table v-model="posts" md-card>
        <md-table-toolbar>
          <h1 class="md-title">Posty</h1>
          <span class="md-caption">Kliknij na post, aby przejść do jego edycji.</span>
        </md-table-toolbar>

        <md-table-row slot="md-table-row" slot-scope="{ item }" @click="editPost(item)">
          <md-table-cell md-label="Tytuł" md-sort-by="title"><span class="md-body-2">{{item.title}}</span></md-table-cell>
          <md-table-cell md-label="ID" md-sort-by="id"><span class="md-body-1">{{item.id}}</span></md-table-cell>
          <md-table-cell md-label="Aktywność" md-sort-by="active"><span v-if="item.active" class="md-body-1">Aktywny</span><span v-if="!item.active" class="md-body-1">Wersja robocza</span></md-table-cell>
        </md-table-row>
      </md-table>
    </div>
    <div class="blog__newPost" v-if="newPost === true">
      <div><span class="md-headline">Utwórz nowy post</span></div>
      <div class="blog__newPost__menu">
        <md-switch v-model="post.active">Post aktywny</md-switch>
        <md-button class="md-dense md-raised md-primary" @click="createPost" v-if="post.id.length === 0">Dodaj post</md-button>
        <md-button class="md-dense md-raised md-primary" @click="updatePost" v-if="post.id.length > 0">Zapisz zmiany w poście</md-button>
        <md-button class="md-raised" @click="backWithoutSaving" v-if="post.id.length > 0">Powrót bez zapisywania</md-button>
        <md-button class="md-raised md-accent" @click="deleteDialog = true" v-if="post.id.length > 0">Usuń post</md-button>
      </div>
      <md-divider></md-divider>
      <div class="blog__newPost__form">
        <div><span class="md-body-2">Konfiguracja</span></div>
        <md-field>
          <label>Tytuł</label>
          <md-input v-model="post.title" maxlength="70"></md-input>
        </md-field>
        <md-field>
          <label>Skrócony opis postu</label>
          <md-input v-model="post.shortText" maxlength="95"></md-input>
        </md-field>
        <md-field>
          <label>Wybierz zdjęcie postu</label>
          <md-file @md-change="uploadSectionLink($event)" accept="image/*" />
        </md-field>
        <div class="image md-elevation-1" v-if="post.image.length>0"><img :src="post.image" ></div>
        <div><span class="md-body-2">Treść</span></div>
        <quillEditor v-model="post.content" :options="options"></quillEditor>
        <md-field class="htmlCode">
          <label>Kod HTML</label>
          <md-textarea v-model="post.content" md-autogrow></md-textarea>
        </md-field>
      </div>
    </div>
    <md-dialog-confirm
          :md-active.sync="deleteDialog"
          md-title="Czy na pewno usunąć post?"
          md-content="Czy masz pewność, że chcesz usunąć ten post? Zmiany nie będą odwracalne."
          md-confirm-text="Usuń"
          md-cancel-text="Cofnij"
          @md-cancel="onCancel"
          @md-confirm="onConfirmDeletePost" />
    <md-snackbar :md-duration="3000" :md-active.sync="showSnackbar" md-persistent>
      <span>{{snackbarMsg}}</span>
    </md-snackbar>
  </div>
</template>

<script>
import {
  db,storage
} from '../configs/firebase'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
 
import { quillEditor } from 'vue-quill-editor';
import moment from 'moment';

export default {
    components: {
    quillEditor
  },
    props: ['userInfo'],
  data() {
    return {
      options: {
        modules: {
          toolbar: [
            ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
            [{ 'header': 1 }, { 'header': 2 }],               // custom button values
            [{ 'list': 'ordered'}, { 'list': 'bullet' }],
            [{ 'color': [] }],          // dropdown with defaults from theme     
            ]
        },
      },
      deleteDialog: false,
      showSnackbar: false,
      snackbarMsg: "Msg",
      newPost: false,
      posts: [],
      imgRef: "",
      post: {
        id: "",
        active: false,
        title: "",
        shortText: "",
        image: "",
        content: `<h1>Test</h1>`,
      }
    }
  },
  created()
  {
    this.getPosts();
  },
  methods: {
        onCancel()
        {
          this.deleteDialog = false;
        },
        async onConfirmDeletePost()
        {
          const logId = db.collection('Logs').doc()
          db.collection('Logs').doc(logId.id).set({
            message: `Post usunięty ${this.post.title}, przez ${this.userInfo.name} ${this.userInfo.surname}, id usuniętego postu - ${this.post.id}`,
          user: {
            mail: this.userInfo.mail,
            name: this.userInfo.name,
            surname: this.userInfo.surname,
          },
            post: this.post,
            createdAt: new Date(),
            uniqueID: logId.id
          })
          
          await db.collection("BlogPosts").doc(this.post.id).delete();
          await this.getPosts();
          this.clearPost();
          this.newPost = false;
        },
        async backWithoutSaving()
        {
          await this.getPosts()
          this.newPost = false;
          this.clearPost();
        },
        clearPost()
        {
          this.post = {
            id: "",
            active: false,
            title: "",
            shortText: "",
            image: "",
          }
        },
        async updatePost()
        {
          try
          {
            await db.collection("BlogPosts").doc(this.post.id).update(this.post);
            this.snackbarMsg = "Post został zaktualizowany."
            this.showSnackbar = true;
            this.newPost = false;
            this.clearPost();
          }
          catch(error)
          {
            console.log(error);
            this.snackbarMsg = "Coś poszło nie tak w trakcie wgrywania postu."
            this.showSnackbar = true;
          }
        },
        editPost(post)
        {
          this.newPost = true;
          this.post = post;
        },
        async getPosts()
        {
          this.posts = [];
          try{
            const result = await db.collection("BlogPosts").orderBy('createdDate','desc').get();
            for(let i=0; i<result.docs.length; i++)
            {
              this.posts.push(result.docs[i].data())
            }
          }
          catch(error)
          {
            console.log(error);
            this.snackbarMsg = "Nie udało się pobrać postów.";
            this.showSnackbar = true;
          }
        },
        async createPost()
        {
          if(this.post.title.length>0 && this.post.shortText.length>0 && this.post.image.length>0 && this.post.content.length>0)
          {
            try {
              this.post.createdDate = moment().toDate()

              const logId = db.collection('Logs').doc()
                db.collection('Logs').doc(logId.id).set({
                  message: `Nowy post dodany ${this.post.title}, przez ${this.userInfo.name} ${this.userInfo.surname}`,
                user: {
                  mail: this.userInfo.mail,
                  name: this.userInfo.name,
                  surname: this.userInfo.surname,
              },
                post: this.post,
                createdAt: new Date(),
                uniqueID: logId.id
              }) 

              let result = await db.collection("BlogPosts").add(this.post);
              this.post.id = result.id;
              try {
                let test = moment().valueOf();
                await db.collection("BlogPosts").doc(this.post.id).update(this.post);
                this.post = {
                  id: "",
                  active: false,
                  title: "",
                  shortText: "",
                  image: "",
                  content: ``,
                }
                this.snackbarMsg = "Post został dodany.";
                await this.getPosts();
                this.newPost = false;
                this.showSnackbar = true;
              }
              catch (error)
              {
                this.snackbarMsg = "Wystąpił błąd podczas dodawania. Więcej w konsoli.";
                this.showSnackbar = true;
                console.log(error);
              }
            }
            catch (error)
            {
              this.snackbarMsg = "Wystąpił błąd podczas dodawania. Więcej w konsoli.";
              this.showSnackbar = true;
              console.log(error);
            }
          }
          else
          {
              this.snackbarMsg = "Wypełnij wszystkie pola oraz wybierz zdjęcie, aby kontynuować.";
              this.showSnackbar = true;
          }
        },
        getFileExtension(filename)
        {
        let ext = /^.+\.([^.]+)$/.exec(filename);
        return ext == null ? "" : ext[1];
        },
        async uploadSectionLink(evt)
        {
          let imgRef = evt[0];
          
            let storageRef = storage.ref();
            let name = this.createFileName(24);
            let ext = this.getFileExtension(imgRef.name)
            let fileRef = storageRef.child(`${name}.${ext}`);
            await fileRef.put(imgRef);
            let link =  await fileRef.getDownloadURL();
            this.post.image = link;
        },
        createFileName(length) {
            var result = [];
            var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
            var charactersLength = characters.length;
            for ( var i = 0; i < length; i++ ) {
            result.push(characters.charAt(Math.floor(Math.random() * 
                charactersLength)));
                }
            return result.join('');
        }
  }
}
</script>
<style lang="scss">

.htmlCode{
  margin-top: 1rem !important;
}

.blog
{
  width: 100%;

  &__menu
  {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    margin-top: 1rem;
  }

  &__newPost
  {
    width: 100%;
    &__menu
    {
      display: flex;
      align-items: center;
      margin-top: 0.8rem;
      margin-bottom: 0.8rem;
    }
    &__form
    {
      margin-top: 1rem;
      margin-bottom: 1rem;
    }
  }
}

.image
{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 200px;
  height: 200px;
  padding: 0.64rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
  background: white;
}


</style>

