import Vue from 'vue'
import App from './App.vue'
import router from './router'
import { vuexStorage } from './vuex_storage/storage.js';

import {
  auth
} from './configs/firebase'
import AOS from 'aos';
import 'aos/dist/aos.css'
AOS.init({duration: 700, offset: 30,once: true})

Vue.config.productionTip = false

export const eventBus = new Vue();

let app;
auth.onAuthStateChanged(() => {
  if (!app) {
    app = new Vue({
      store: vuexStorage,
      router,
      render: function (h) {
        return h(App)
      },
      mounted(){
        let scrollRef = 0;

        window.addEventListener('scroll', function() {
          // increase value up to 10, then refresh AOS
          scrollRef <= 50 ? scrollRef++ : AOS.refresh();
        });
      },
    }).$mount('#app')
  }
})