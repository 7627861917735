<template>
    <div class="productTile" :id="product.uniqueID">
        <router-link class="productTile__container" :to="`/produkt/${product.uniqueID}?p=${productTitle}`" target="_blank">
            <div v-if="product.qualityClass.length > 0 && (product.category === 'Komputery' || product.category === 'Laptopy' || product.category === 'Monitory' || product.category === 'Tablety' || product.category === 'Telefony')" class="productTile__container__qualityClass">
                <div :class="{productTile__container__qualityClass__label: true, labelExhibition: product.qualityClass === 'Powystawowy', labelA: product.qualityClass === 'Nowy' || product.qualityClass === 'A+' || product.qualityClass === 'A' || product.qualityClass === 'A-', labelB: product.qualityClass === 'B+' || product.qualityClass === 'B' || product.qualityClass === 'B-', labelC: product.qualityClass === 'C+' || product.qualityClass === 'C' || product.qualityClass === 'C-', labelFix: (product.promotion && product.isUnique === false) || (product.promotion && product.isUnique) | (product.isUnique === false && product.quantity < 5 && product.promotion === false)}">
                    <span v-if="product.qualityClass === 'A+' || product.qualityClass === 'A' || product.qualityClass === 'A-' || product.qualityClass === 'B+' || product.qualityClass === 'B' || product.qualityClass === 'B-' || product.qualityClass === 'C+' || product.qualityClass === 'C' || product.qualityClass === 'C-'">Klasa {{product.qualityClass}}</span>
                    <span v-if="product.qualityClass === 'Nowy' || product.qualityClass === 'Powystawowy'">{{product.qualityClass}}</span>
                </div>
            </div>
            <div v-if="(product.promotion && product.isUnique === false) || (product.isUnique) | (product.isUnique === false && product.quantity < 5 && product.promotion === false)" class="productTile__container__special">
                <span class="redSpan bolded" v-if="product.promotion && product.isUnique === false">Promocja</span>
                <span class="redSpan" v-if="product.isUnique">Konkretna <span class="graySpan">Sztuka</span></span>
                <span class="redSpan" v-if="product.isUnique === false && product.quantity < 5 && product.promotion === false">Ostatnie <span class="graySpan">Sztuki</span></span>
            </div>
            <div class="productTile__container__content">
                <div class="productTile__container__content__image">
                    <img class="productTile__container__content__image__img" :src="product.mainImage.url" :alt="product.mainImage.keyword">
                </div>
                <div class="productTile__container__content__name">
                    <h3>{{product.name}}</h3>
                </div>
                <div class="productTile__container__content__snippet">
                    <div v-for="(snippet,snIndex) in product.snippet" :key="snippet"> <span v-if="snIndex > 0" class="snippetConnector">-</span>{{snippet}}</div>
                </div>
                <span class="productTile__beforePromotion" v-if="product.promotion && product.isUnique === false && (product.priceBeforePromotion > product.basePrice)">{{product.priceBeforePromotion}} PLN</span>
                <div :class="{productTile__container__content__price: true, productTile__promotion: product.promotion && product.isUnique === false && (product.priceBeforePromotion > product.basePrice)}">
                    <div>{{product.basePrice.toFixed(2)}} PLN</div>
                </div>
                <div class="productTile__container__content__callToAction">
                    <div class="productTile__container__content__callToAction__value">
                        Zobacz
                    </div>
                    <div class="productTile__container__content__callToAction__icon">
                        <i class="fi-rr-angle-small-right smallIcon"></i>
                    </div>
                </div>
            </div>
        </router-link>
    </div>
</template>
<script>


    export default {
    data() {
        return {
            productTitle: "",
        }
    },
    methods: {
        createProductTitle(){
            this.productTitle = this.product.name.replace(/ /g,"-")
        }
    },
    created(){
        this.createProductTitle();
    },
    props: ['product']
    }
</script>
<style lang="scss" scoped>
@import '../styles/variables';
    .snippetConnector
    {
        color: $connectorColor;
        margin-left: 0.2em;
        margin-right: 0.2em;
    }

    .smallIcon
    {
        font-size: 1rem;
    }

    h3{
        font-size: 1rem;
        font-weight: 500;
        margin: 0;
    }
    
    .productTile
    {
        display: flex;
        flex-direction: column;
        border: 1px solid $border;
        width: 24%;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;

        &__beforePromotion {
            color: $red;
            font-size: 1rem;
            font-weight: 500;
            text-decoration: line-through;
            line-height: 1rem;  
        }

        &__promotion {
            line-height: 1.25rem !important;
            margin-top: 0 !important;
        }

        &__container
        {
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding: 5%;
            width: 90%;
            height: 90%;
            transition: box-shadow 0.4s ease-in-out;

            &__special
            {
                height: 7%;
                z-index: 300;
            }

            &__qualityClass
            {
                font-size: 0.8rem;
                position: relative;
                height: 0;
                width: 100%;
                // background: $black;  
                &__label{
                    z-index: 10;
                    position: absolute;
                    right: -5.5%;
                    top: -15px;
                    height: 1.563rem;
                    width: 4rem;
                    background: black;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    box-shadow: 0 1px 3px rgba(0,0,0,0.10), 0 1px 2px rgba(0,0,0,0.16);
                    clip-path: polygon(100% 0, 100% 85%, 100% 100%, 0% 100%, 10% 50%, 0 0);
                    span{
                        color: #ffffff;
                        padding-left: 10%;
                    }
                }
            }

            &__content
            {
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                width: 100%;
                height: 93%;
                
                &__name
                {
                    margin-top: 0.64rem;
                    text-align: center;
                }

                &__image
                {
                    margin-top: 0.3125em;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 90%;
                    height: 60%;
                    background: #ffffff !important;
                    border: none !important;
                    
                    
                    img
                    {
                        transition: all 0.7s ease-in-out;
                    }
                    
                    &__img
                    {
                        object-fit: fill;
                        max-width: 60%;
                        max-height: 100%;
                        background: #ffffff !important;
                        border: none !important;
                    }
                }

                &__snippet
                {
                    width: 100%;
                    font-size: 0.8rem;
                    text-align: center;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }

                &__price
                {
                    width: 100%;
                    text-align: center;
                    // margin-top: 0.262rem;
                    display: flex;
                    justify-content: center;
                    font-size: 1.25rem;
                    font-weight: 500;
                }

                &__callToAction
                {
                    display: flex;
                    width: 100%;
                    justify-content: center;
                    align-items: center;
                    // margin-top: auto;
                    font-size: 1rem;
                    color: $red;
                    transition: all 0.4s ease-in-out;
                    text-align: center;
                    &__icon{
                        i{
                            display: flex;
                            justify-content: center;
                            align-items: center;
                        }
                    }
                }
            }

        }

        &__container:hover
        {
            box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
        }
        
    }

    .labelA{
        background: #00a606;
    }
    .labelB{
        background: #c2cc00;
    }
    .labelC{
        background: #e77f00;
    }
    .labelExhibition{
        background: #00a606;
        width: 5.96rem;
        font-size: 0.64rem;
    }
    .labelFix{
        top: -6px;
    }
    .redSpan
    {
        z-index: 500;
        color: $red;
        font-size: 1.115rem !important;
    }
    .bolded{
        font-weight: 500;
        // font-size: 1rem;
    }

    .graySpan
    {
        z-index: 500;
        color: $gray;
    }

    .contain
    {
    object-fit: contain;
    }

    .productTile__container:hover .productTile__container__content__image>img
    {
        transform: scale(1.05);
    }

    .productTile__container:hover .productTile__container__content__callToAction
    {
        color: $redHover
    }

    @media (max-width: 1024px)
    {
        .productTile
        {
            margin-top: 1.563rem;
            width: 100%;
        }

    }

    @media (max-width: 769px)
    {
        .productTile__container__qualityClass__label{
            top: 0;
        }
    }

</style>