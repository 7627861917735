<template>
    <div class="stepper">
        <div :class="{'step':true, 'active': firstStep.active, 'completed': firstStep.completed}">
            <div class="step-label"></div>
            <div class="step-description">Koszyk</div>
        </div>
        <div :class="{'step':true, 'active': secondStep.active, 'completed': secondStep.completed}">
            <div class="step-label"></div>
            <div class="step-description">Dane podstawowe</div>
        </div>
        <div :class="{'step':true, 'active': thirdStep.active, 'completed': thirdStep.completed}">
            <div class="step-label"></div>
            <div class="step-description">Dane do wysyłki</div>
        </div>
        <div :class="{'step':true, 'active': fourthStep.active, 'completed': fourthStep.completed}">
            <div class="step-label"></div>
            <div class="step-description">Finalizacja</div>
        </div>
    </div>
</template>

<script>


export default {
  data() {
    return {
        firstStep: {
            active: true,
            completed: false
        },
        secondStep: {
            active: false,
            completed: false
        },
        thirdStep: {
            active: false,
            completed: false
        },
        fourthStep: {
            active: false,
            completed: false
        }
    }
  },
  methods: {
      clearSteps()
      {
          this.firstStep.active = false;
          this.firstStep.completed = false;
          this.secondStep.active = false;
          this.secondStep.completed = false;
          this.thirdStep.active = false;
          this.thirdStep.completed = false;
          this.fourthStep.active = false;
          this.fourthStep.completed = false;
      },
      changeStatus()
      {
        this.clearSteps();
        if(this.statuses[0].length>0)
        {
            this.firstStep[`${this.statuses[0]}`] = true;
        }
        if(this.statuses[1].length>0)
        {
            this.secondStep[`${this.statuses[1]}`] = true;
        }
        if(this.statuses[2].length>0)
        {
            this.thirdStep[`${this.statuses[2]}`] = true;
        }
        if(this.statuses[3].length>0)
        {
            this.fourthStep[`${this.statuses[3]}`] = true;
        }
      }
  },
watch: {
      statuses: function () {
          this.changeStatus()
      }
},
  components: {},
  created()
  {
      this.changeStatus()

  },
  props: ['statuses']
}
</script>
<style lang="scss" scoped>
// Import variables
@import '../styles/variables';


.stepper {
	font-size: 1rem;
	position: relative;
	counter-reset: step 0;
	width: 100%;
	margin: 2rem 0;
	display: flex;
	flex-flow: row nowrap;
	gap: 0;
	justify-content: space-around;
	align-items: flex-start;
}
.stepper > .step {
	position: relative;
	counter-increment: step;
	width: 100%;
	display: flex;
	flex-flow: column nowrap;
	justify-content: flex-start;
	align-items: center;
}
.stepper > .step::before {
	position: absolute;
	content: "";
	width: 100%;
	height: 2px;
	background: $border;
	left: 0;
	top: 1.1rem;
}
.stepper > .step > .step-label {
	position: relative;
	width: 2.2rem;
	height: 2.2rem;
	line-height: 2.2rem;
	background: $border;
	color: #b8b8b8;
	text-align: center;
	border-radius: 50%;
	margin: 0 auto;
}
.stepper > .step > .step-label::before {
	content: counter(step);
}
.stepper > .step.active::before {
	background: linear-gradient(to right, $red, $border);
}
.stepper > .step.active > .step-label {
	background: $red;
	color: $border;
	font-weight: bold;
}
.stepper > .step.completed::before {
	background: $red;
}
.stepper > .step.completed > .step-label {
	background: $red;
	color: $border;
}
.stepper > .step > .step-description {
	font-size: x-small;
	color: $gray;
	margin-top: 0.5rem;
	overflow-wrap: break-word;
	hyphens: auto;
	text-align: center;
}
.stepper > .step.active > .step-description,
.stepper > .step.completed > .step-description {
	color: $gray;
}
.stepper > .step.active > .step-description {
	font-weight: 500;
}



</style>