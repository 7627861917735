<template>
    <div @mouseenter="showBar" @mouseleave="hideBar" class="navbarContainer">
        <div :class="{scrolling: barHidden,navbarContainer__desktop: true}">
            <div class="navbarContainer__desktop__logo">
                <router-link to="/">
                    <img src="../assets/kompreLogo-min.png" alt="Laptopy poleasingowe oraz komputery używane. Sprawdź sklep komputerowy Kompre. Najlepsze laptopy uzywane.">
                </router-link>
            </div>
            <div class="navbarContainer__desktop__goto">
                <div v-for="category in categories" :key="category.sort" class="navbarContainer__desktop__goto__link">
                    <router-link v-if="!category.dropdown" :class="{routeDisabled: routeName === category.link}" :to="routeName === category.link ? `${category.link}` : `/kategorie/${category.link}`">
                        {{category.name}}
                    </router-link>
                    <div class="navbarContainer__desktop__goto__dropdown" v-if="category.dropdown">
                        <div class="navbarContainer__desktop__goto__dropdown__head">{{category.name}} <i :class="{'fi-rr-angle-small-down icon': true, iconSmallDropdown: true}"></i></div>
                        <div class="navbarContainer__desktop__goto__dropdown__options" v-if="category.dropdown === true && category.dropdownOptions.length>0">
                            <div class="navbarContainer__desktop__goto__dropdown__options__option" v-for="(option,optionIndx) in category.dropdownOptions" :key="optionIndx">
                                <router-link :class="{routeDisabled: routeName === option.link}" :to="routeName === option.link ? `${option.link}` : `/kategorie/${option.link}`">
                                    {{option.name}}
                                </router-link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="navbarContainer__desktop__icons">
                
                <div class="navbarContainer__desktop__icons__icon">
                    <router-link to="/szukaj">
                        <i class="fi-rr-search icon"></i>
                    </router-link>
                </div>
                <div class="navbarContainer__desktop__icons__icon">
                    
                    <router-link :class="{notEmpty: $store.state.cart.length > 0, emptyCart: $store.state.cart.length === 0}" to="/koszyk">
                        <i :class="{'fi-rr-shopping-cart': true, icon: true}"></i>
                        <div :class="{cartLength: true, cartLengthHidden: barHidden, cartLengthSmaller: displayBar}" v-if="$store.state.cart.length > 0">{{$store.state.cart.length}}</div>
                    </router-link>
                </div>
                
            </div>
        </div>
        <div :class="{smallBar: barHidden ,hidden: displayBar, navbarContainer__desktopBar: true}">
            <div class="navbarContainer__desktopBar__content">
                <a href="https://b2b.kompre.pl/">
                    <div class="navbarContainer__desktopBar__content__reference">
                        <div class="navbarContainer__desktopBar__content__reference__image">
                            <img src="../assets/navbarImgs/b2b.svg" alt="tba">
                        </div>
                        <div class="navbarContainer__desktopBar__content__reference__text">
                            <span>B2B</span>
                        </div>
                    </div>
                </a>

                <router-link to="/kategorie/uslugi">
                    <div class="navbarContainer__desktopBar__content__reference">
                        <div class="navbarContainer__desktopBar__content__reference__image">
                            <img src="../assets/navbarImgs/customer-service.svg" alt="tba">
                        </div>
                        <div class="navbarContainer__desktopBar__content__reference__text">
                            <span>Usługi</span>
                        </div>
                    </div>
                </router-link>

                <router-link to="/zdjecia">
                    <div class="navbarContainer__desktopBar__content__reference">
                        <div class="navbarContainer__desktopBar__content__reference__image">
                            <img src="../assets/navbarImgs/product-photo.svg" alt="tba">
                        </div>
                        <div class="navbarContainer__desktopBar__content__reference__text">
                            <span>Zdjęcia przed wysyłką</span>
                        </div>
                    </div>
                </router-link>

                <router-link to="/vat0">
                    <div class="navbarContainer__desktopBar__content__reference">
                        <div class="navbarContainer__desktopBar__content__reference__image">
                            <img src="../assets/navbarImgs/tax.svg" alt="tba">
                        </div>
                        <div class="navbarContainer__desktopBar__content__reference__text">
                            <span>VAT 0%</span>
                        </div>
                    </div>
                </router-link>

                <router-link to="/bezpieczenstwo">
                    <div class="navbarContainer__desktopBar__content__reference">
                        <div class="navbarContainer__desktopBar__content__reference__image">
                            <img src="../assets/navbarImgs/logistics-delivery.svg" alt="tba">
                        </div>
                        <div class="navbarContainer__desktopBar__content__reference__text">
                            <span>Bezpieczne zakupy</span>
                        </div>
                    </div>
                </router-link>

                <a href="https://blog-kompre.web.app" target="_blank">
                    <div class="navbarContainer__desktopBar__content__reference">
                        <div class="navbarContainer__desktopBar__content__reference__image">
                            <img src="../assets/navbarImgs/blog.svg" alt="tba">
                        </div>
                        <div class="navbarContainer__desktopBar__content__reference__text">
                            <span>Blog</span>
                        </div>
                    </div>
                </a>

                <router-link to="/promocje">
                    <div class="navbarContainer__desktopBar__content__reference">
                        <div class="navbarContainer__desktopBar__content__reference__image">
                            <img src="../assets/navbarImgs/price-tag.svg" alt="tba">
                        </div>
                        <div class="navbarContainer__desktopBar__content__reference__text">
                            <span>Promocje</span>
                        </div>
                    </div>
                </router-link>
            </div>
        </div>
        <div class="navbarContainer__mobile">
            <div class="navbarContainer__mobile__mainbar">
                <div class="navbarContainer__mobile__mainbar__logo">
                    <router-link to="/">
                        <img src="../assets/kompreLogo-min.png" alt="Komputery poleasingowe poleasingowe i laptopy używane. Sprawdź sklep komputerowy Kompre. Najlepsze monitory używane">
                    </router-link>
                </div>
                <div class="navbarContainer__mobile__mainbar__hamburgerMenu">
                    <transition name="fade">
                        <router-link to="/szukaj" v-if="showMobileMenu === false">
                            <i class="fi-rr-search mobileIcon" ></i>
                        </router-link>
                    </transition>
                    <transition name="fade">
                        <router-link to="/koszyk" v-if="showMobileMenu === false">
                            <i :class="{'fi-rr-shopping-cart': true, 'mobileIcon': true, 'notEmptyMobileCart': $store.state.cart.length > 0}"></i>
                        </router-link>
                        
                    </transition>
                    <i class="fi-rr-menu-burger mobileIcon" v-if="showMobileMenu === false" @click="toggleMenu"></i>
                    <i class="fi-rr-cross icon" v-if="showMobileMenu === true" @click="toggleMenu"></i>
                </div>
            </div>
                <transition name="fade">
                    <div class="navbarContainer__mobile__openMenu" v-if="showMobileMenu">
                        <div class="navbarContainer__mobile__openMenu__toggle">
                            <div class="navbarContainer__mobile__openMenu__toggle__link" @click="toggleMenu" v-for="category in categoriesMobile" :key="category.sort">
                                <router-link :to="`/kategorie/${category.link}`">
                                    {{category.name}}
                                </router-link>
                            </div>
                            <div class="navbarContainer__mobile__openMenu__toggle__link" @click="toggleMenu">
                                <a href="http://b2b.kompre.pl/">
                                    B2B
                                </a>
                            </div>
                            <div class="navbarContainer__mobile__openMenu__toggle__link" @click="toggleMenu">
                                <router-link to="/zdjecia">
                                    Zdjęcia przed wysyłką
                                </router-link>
                            </div>
                            <div class="navbarContainer__mobile__openMenu__toggle__link" @click="toggleMenu">
                                <router-link to="/vat0">
                                    VAT 0%
                                </router-link>
                            </div>
                            <div class="navbarContainer__mobile__openMenu__toggle__link" @click="toggleMenu">
                                <router-link to="/bezpieczenstwo">
                                    Bezpieczne zakupy
                                </router-link>
                            </div>
                            <div class="navbarContainer__mobile__openMenu__toggle__link" @click="toggleMenu">
                                <a href="https://blog.kompre.pl/">
                                    Blog
                                </a>
                            </div>
                            <div class="navbarContainer__mobile__openMenu__toggle__link" @click="toggleMenu">
                                <router-link to="/promocje">
                                    Promocje
                                </router-link>
                            </div>
                        </div>
                    </div>
                </transition>
        </div>
    </div>
</template>
<script>
// Imports
import {
  db
} from '../configs/firebase'

export default {
  data() {
    return {
        categories: [],
        categoriesMobile: [],
        showMobileMenu: false,
        scrollPosition: null,
        routeName: '',
        barHidden: false,
        displayBar: false,
    }
  },
  created() {
      this.getCurrentRoute();
  },
  mounted() {
      window.addEventListener('scroll', this.updateScroll);
  },
  watch:
  {
      $route(to, from)
      {
        window.scrollTo(0,0);
        this.getCurrentRoute();
      },
      scrollPosition(){
          if(this.scrollPosition > 50){
              this.barHidden = true
          }else{
              this.barHidden = false
          }
      },
      barHidden(){
          if(this.barHidden === true){
            setTimeout(() => this.displayBar = true, 150);
          }else{
              this.displayBar = false
          }
      }
  },
  methods: {
        showBar(){
            this.barHidden= false
        },
        hideBar(){
            if(this.scrollPosition > 50){
                this.barHidden= true
            }
        },
        toggleMenu()
        {
            this.showMobileMenu = !this.showMobileMenu;
        },
        updateScroll() {
        this.scrollPosition = window.scrollY;
        },
        getCurrentRoute()
        {
            if(this.$route.params.category !== undefined)
            {
                this.routeName = this.$route.params.category;
            }
        }
  },
  beforeCreate()
  {
        //Get Categories from db
        db.collection('Categories').where("showInNavbar","==", true).get().then((data)=>
        {
            // Unpack categories
            for(let i=0; i < data.docs.length; i++)
            {
                let current = data.docs[i].data();
                this.categories.push(current);
            }
            // Sort categories
            this.categories.sort(function(a,b)
            {
                return a.sort - b.sort
            })
            db.collection('Categories').where("showInMobileNav","==", true).get().then((data)=>
            {
                // Unpack categories
                for(let i=0; i < data.docs.length; i++)
                {
                    let current = data.docs[i].data();
                    this.categoriesMobile.push(current);
                }
                
                this.categoriesMobile.sort(function(a,b)
                {
                    return a.sort - b.sort
                })
            }).catch((error)=>
            {
                console.log('Error: Cannot get categories. More:')
                console.log(error);
            })
            /*
            this.categoriesMobile.sort(function(a,b)
            {
                return a.sort - b.sort
            })
            */
        }).catch((error)=>
        {
            console.log('Error: Cannot get categories. More:')
            console.log(error);
        })
  }
}
</script>
<style lang="scss" scoped>
@import '../styles/variables';






.scrolling
{
    box-shadow: 0 1px 3px rgba(0,0,0,0.10), 0 1px 2px rgba(0,0,0,0.16);
    height: 5.96rem !important;
}

.hidden{
    display: none !important;
}

.smallBar{
    height: 0 !important;
}


.fade-enter-from
{
    opacity: 0;
}

.fade-enter-to
{
    opacity: 1;
}

.fade-enter-active
{
    transition: opacity 0.2s ease;
}

.fade-leave-from
{
    opacity: 1;
}

.fade-leave-to
{
    opacity: 0;
}

.fade-leave-active
{
    transition: opacity 0.2s ease;
}


.icon:hover
{
    color: $red;
}

.icon
{
    font-size: 1.25em;
}

.iconSmallDropdown
{
    display: flex;
    font-size: 1em;
    align-items: center;
    justify-content: center;
}

.mobileIcon
{
    font-size: 1.65em;
    color: $gray;
}

.mobileIcon:hover
{
    color: $red;
}

.mobileIconClose
{
    font-size: 1.95em;
    padding-top: 0.15em;
    color: $gray;
}


.navbarContainer
{
    position: fixed;
    top: 0;
    background-color: white;
    z-index: 1000;
    width: 100vw;
    box-shadow: none;

    &__mobile
    {
        display: none;
    }
    &__desktopBar{
        width: 100%;
        height: 3.052rem;
        background: #f0f0f0;
        border-top: 1px solid #d5d5d5;
        transition: all 0.3s ease-in-out;
        display: flex;
        justify-content: center;
        box-shadow: 0 1px 3px rgba(0,0,0,0.06), 0 1px 2px rgba(0,0,0,0.12);
        &__content{
            height: 100%;
            width: 70%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            a{
                cursor: auto;
            }
            &__reference{
                display: flex;
                align-items: center;
                height: 1.563rem;
                transition: all 0.1s ease-in-out;
                cursor: pointer;
                &__image{
                    height: 100%;
                    margin-right: 0.41rem;
                    img{
                        height: 100%;
                        width: auto;
                    }
                }
                &__text{
                    font-size: 0.8rem;
                    font-weight: 400;
                }
            }
            &__reference:hover{
                color: $red;
            }
            
        }
    }
    &__desktop
    {
        width: 90%;
        padding-left: 5%;
        padding-right: 5%;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        height: 7.125em;
        transition: all 0.2s ease-in-out;
        &__logo
        {
            display: flex;
            align-items: center;
            width: 15%;
            img
            {
                height: auto;
                width: 100%;
            }
        }

        &__goto
        {
            display: flex;
            align-items: center;
            width: 50%;
            padding-left: 1rem;
            padding-right: 1rem;
            justify-content: space-around;
            font-weight: 500;
            user-select: none;

            &__dropdown
            {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                justify-content: center;

                &__head
                {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }

                &__head:hover
                {
                    color: $red;
                }

                &__options
                {
                    position: absolute;
                    top: 4.4rem;
                    background-color: white;
                    padding: 0.8rem;
                    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
                    visibility: hidden;
                    opacity: 0;
                    transition: all 0.3s ease-in;
                    
                    &__option
                    {
                        display: flex;
                        align-items: center;
                        font-weight: 500;
                        padding: 0.64rem;
                        border-radius: 3px;
                        font-size: 1rem;
                        transition: all 0.3s ease-in;  
                        border-bottom: 1px solid $border;

                    }

                    &__option:hover
                    {
                        color: $red;
                        background: #f9f9f9;
                    }
                }
            }

            &__dropdown:hover
            {
                cursor: pointer;
            }

            &__dropdown:hover>&__dropdown__options
            {
                opacity: 1;
                visibility: visible;
            }


            &__dropdown__head:hover>.iconSmallDropdown
            {
                color: $red;
            }

            &__link
            {
                display: flex;
                //margin-left: 3.125em;

                a:hover
                {
                    color: $red;
                }
            }
        }

        &__icons
        {
            margin-left: auto;
            display: flex;

            &__icon
            {
                width: 1em;
                margin-left: 3.125em;
            }
        }
    }
}

@media (max-width: 1280px) {
    .navbarContainer
    {
        &__desktop
        {
            &__goto
            {
                width: 60%;
            }
        }
        &__desktopBar{
            &__content{
                width: 80%;
            }
        }
    }
    .cartLength{
        right: 3.75% !important;
    }
}

@media (max-width: 1024px) {
    .navbarContainer
    {
        font-size: 14px;
        &__desktop
        {
            &__goto
            {
                width: 70%;
            }
        }
        &__desktopBar{
            &__content{
                width: 90%;
            }
        }
    }
    .cartLength{
        right: 3.25% !important;
    }
}




@media (max-width: 769px) {
    .navbarContainer
    {
        font-size: 16px;
        &__desktop
        {
            display: none;
        }
        &__desktopBar
        {
            display: none !important;
        }

        &__mobile
        {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
            width: 100vw;

            &__openMenu
            {
                height: 100vh;
                width: 100vw;
                display: flex;
                background: linear-gradient(#ffffff 55%, $red);

                &__toggle
                {
                    display: flex;
                    flex-direction: column;
                    width: 90%;
                    align-items: flex-start;
                    justify-content: space-around;
                    height: 80%;
                    padding-left: 5%;
                    padding-right: 5%;
                    

                    &__link
                    {
                        font-size: 1.266rem;
                    }
                    &__link:hover
                    {
                        color: $red;
                    }
                }
            }

            &__mainbar
            {
                padding-top: 0.5em;
                padding-bottom: 0.5em;
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100%;
                padding-left: 5%;
                padding-right: 5%;

                &__logo
                {
                    display: flex;
                    align-items: center;
                    width: 45%;
                    img
                    {
                        width: 11em;
                        height: auto;
                    }
                }

                &__logo:hover
                {
                    cursor: pointer;
                }

                &__hamburgerMenu
                {
                    display: flex;
                    align-items: center;
                    width: 45%;
                    justify-content: flex-end;

                    i
                    {
                        margin-left: 1em;
                    }

                    i:hover
                    {cursor: pointer;}
                }

            }
        }
    }
}

.routeDisabled
{
    pointer-events: none;
    color: $red;
    cursor: not-allowed;
}

.cartLength
{
    font-size: 0.8rem;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    color: white;
    background: $red;  
    right: 4%;
    bottom: 55%;
    width: 1.25rem;
    height: 1.25rem;
    border-radius: 100%;
    opacity: 1;
}
.cartLengthHidden{
    opacity: 0;
}
.cartLengthSmaller{
    bottom: 33% !important;
    opacity: 1  !important;
}


.notEmpty
{
    color: $red;
}

.notEmpty:hover
{
    opacity: 0.7;
}

.emptyCart
{
    opacity: 0.4;
}

.notEmptyMobileCart
{
    color: $red;
}
</style>