<template>
  <div class="category">
    <navbar/>
    <div class="category__container" v-if="!loading">
        <div class="category__container__texts">
            <div class="category__container__texts__column">
                <div class="category__container__texts__header">
                    <h1>{{ categoryData.name }}</h1>
                </div>
                <div class="category__container__texts__subheader">
                    <h3>{{ categoryData.subheader }}</h3>
                </div>
            </div>
            <div class="category__container__filters__header" v-show="renderFilters" @click="toggleFilters"><span><i class="fi fi-rr-filter"></i> Pokaż dostępne filtry</span></div>
        </div>
        <div class="category__container__infoFilter" v-if="typeOfSearch === 'filter'">
            <span>Filtr włączony ({{productBucket.length}})</span>
            <div @click="resetFilter()"><i class="fi-rr-cross-circle"></i></div>
        </div>
        <div class="category__container__productGrid" v-if="productBucket.length > 0">
            <div class="category__container__productGrid__row" v-for="(i,rowIndx) in Math.ceil(productBucket.length / 4)" :key="rowIndx" data-aos="fade-up">
                <productTile :product="product" v-for="product in productBucket.slice((i - 1) * 4, i * 4)" :key="product.uniqueID"></productTile>
            </div>
        </div>
    </div>
    <div class="category__container__loadMore" v-if="!loading && showMoreButton && productBucket.length > 0 && typeOfSearch === 'firebase'">
        <button @click="nextBatch(lastItemID)"> Załaduj więcej</button>
    </div>
    
    <loader v-if="loading">test</loader>
    <emptyState v-if="productBucket.length === 0 && !loading"></emptyState>
    <trust/>
    <ftr/>
    
    <transition name="fade">
        <filterModal @applyFilter="applyFilter" @resetFilter="resetFilter" :activeData="filterSettings" :options="filterOptions" :category="categoryData.name" :arrayOfRef="categoryData.productsID" v-if="showFilters === true" @close="closeModal"></filterModal>
    </transition>
  </div>
</template>
<script>

    // Imports
    import {
    db,firebase
    } from '../configs/firebase'
    // Imports of components
    import navbar from "../components/navbarStore.vue";
    import trust from "../components/trust.vue";
    import ftr from "../components/footer.vue";
    import emptyState from "../components/emptyState.vue";
    import productTile from "../components/productTile.vue";
    import loader from "../components/loader.vue";
    import selector from "../components/selector.vue";
    import filterModal  from '@/components/filterModal.vue';

    String.prototype.capitalize = function() {
        return this.charAt(0).toUpperCase() + this.slice(1);
    }

    export default {
        emits: ["toggleScroll"],
    data() {
        return {
            refs: [],
            products: [],
            renderFilters: false,
            resetInput: false,
            typeOfSearch: 'firebase',
            algolia: {
                result: null,
                filter: "",
                currentPage: 0,
                numberOfPages: 0
            },
            lastVisible: "",
            query: "",
            selectorValues: {
                manufacturer: "",
                qualityClass: "",
                subcategory: "",
                sortBy: "asc"
            },
            showFilters: false,
            productBucket: [],
            categoryData: null,
            lastItemID: "",
            limit: 24,
            loading: true,
            showMoreButton: true,
            // Filters
            filterOptions: [],
            filterSettings: null,
        }
    },
    methods: {
        resetFilter()
        {
            this.getData();
            if(this.$route.query.filters !== undefined){
            this.$router.replace({query: {}})
            }
        },
        applyFilter(data)
        {
            this.filterSettings = data;
            this.productBucket = data.products;
            this.typeOfSearch = 'filter';
        },
        closeModal(){
            this.showFilters = false;
            this.$emit("toggleScroll");
        },
        toggleFilters()
        {
            setTimeout(()=>
            {
                this.showFilters = !this.showFilters;   
                this.$emit("toggleScroll");    
            },500)
        },
        getNewData()
        {
            this.productBucket = [];
            let db = firebase.firestore();
            this.query = eval(this.getQuery());
            this.query.limit(this.limit).get().then((data)=>{
                    if(data.docs.length<this.limit)
                    {
                        this.showMoreButton = false;
                    }
                    for(let i = 0; i < data.docs.length; i++)
                    {
                        let currentItem = data.docs[i].data();
                        this.productBucket.push(currentItem);
                        if(i === data.docs.length-1)
                        {
                            this.lastItemID = currentItem.uniqueID;
                            this.lastVisible = currentItem;
                        }
                    }
            }).catch((error)=>{
                console.log("Error on filtered data.");
                console.log(error);
            })
        },
        getQuery()
        {
            let query = "db.collection('Products').where('active','==', true).where('category','==', this.categoryData.name)";
            if(this.selectorValues.qualityClass.length>0)
            {
                query = query + `.where('qualityClass','==',this.selectorValues.qualityClass)`
            }
            if(this.selectorValues.subcategory.length>0)
            {
                query = query + `.where('subcategory','==',this.selectorValues.subcategory)`
            }
            if(this.selectorValues.manufacturer.length>0)
            {
                query = query + `.where('manufacturer','==',this.selectorValues.manufacturer)`
            }
            if(this.selectorValues.sortBy === 'desc')
            {
                query = query + '.orderBy("basePrice", "desc")';
            }
            else 
            {
                query = query + '.orderBy("basePrice")';
            }
            return query;
        },
        queryChange(data)
        {
            let selector = this.selectorValues;
            selector[data.name]= data.value;
        },
        nextBatch()
        {
            this.query.startAfter(this.lastVisible.basePrice).limit(this.limit).get().then((data)=>
            {
                if(data.empty === false)
                {
                    if(data.docs.length<this.limit)
                    {
                        this.showMoreButton = false;
                    }
                    for(let i = 0; i < data.docs.length; i++)
                    {
                        
                        let currentItem = data.docs[i].data();
                        this.productBucket.push(currentItem);
                        if(i === data.docs.length-1)
                        {
                            this.lastItemID = currentItem.uniqueID;
                            this.lastVisible = currentItem; 
                        }
                    }
                }
                else
                {
                    this.showMoreButton = false;
                    this.loading = false;
                }

            }).catch((error)=>
            {
                console.log("Error: Cannot get next batch.");
                console.log(error)
            })

        },
        unpackData(data)
        {
            let unpackedData = [];
            for(let i=0; i< data.docs.length; i++)
            {
                let currentItem = data.docs[i].data();
                {
                    this.lastItemID = currentItem.uniqueID;
                    this.lastVisible = currentItem;
                }
                unpackedData.push(currentItem);
            }
            return unpackedData;
        },
        clearData()
        {
            this.loading = true;
            this.filterSettings = null;
            this.showFilters = false;
            this.typeOfSearch = 'firebase';
            this.showFilters = false;
            this.lastItemID = "";
            this.productBucket = [];
            this.categoryData = {};
            this.query = "";
            this.showMoreButton = true;
            this.algolia = {
                result: null,
                filter: "",
                currentPage: 0,
                numberOfPages: 0
            };
            this.filterOptions = [];
            // this.turnOffLoading();
        },
        turnOffLoading()
        {
                this.loading = false;
        },
        setMeta() {
        document.title = this.categoryData.seo.title;
        document
            .querySelector('meta[name="keywords"]')
            .setAttribute("content", this.categoryData.seo.metaKeywords);
        document
            .querySelector('meta[name="description"]')
            .setAttribute("content", this.categoryData.seo.metaDesc);
        },
        // Get filter values
        async getFilterValues()
        {
            for(let i=0;i<this.categoryData.activeFilters.length;i++)
            {
                try
                {
                    const result = await db.collection('Attributes').doc(this.categoryData.activeFilters[i]).get();
                    this.filterOptions.push(result.data())
                }
                catch (error)
                {
                    console.log(error);
                }
            }
        },
        // Get data from db
        async getData()
        {   
            this.clearData();
            this.typeOfSearch = 'firebase';
            //Get Categories from db
            db.collection('Categories').where('link', '==', this.$route.params.category).get().then(async (doc)=>
            {
                if(!doc.empty)
                {
                    this.categoryData = doc.docs[0].data();
                    this.setMeta();
                    this.getFilterValues();
                    let db = firebase.firestore();
                    this.query = eval(this.getQuery())
                    //db.collection('Products').where("active","==", true).where("category","==", this.categoryData.name).orderBy("basePrice", this.selectorValues.sortBy).limit(this.limit)
                    this.query.limit(this.limit).get().then(async (productDoc)=>
                    {
                        if(!productDoc.empty)
                        {
                            this.productBucket = this.unpackData(productDoc);
                            // console.log(this.productBucket);
                            if(productDoc.docs.length<this.limit)
                            {
                                this.showMoreButton = false;
                            }
                            if(this.$route.query.filters !== undefined){
                                const queryString = require('query-string');
                                const parsed = queryString.parse(this.$route.query.filters);
                                let chosenValues = {}
                                chosenValues = parsed
                                this.filterSettings = {
                                    currentValues: chosenValues
                                }
                                for(let i=0;i<this.categoryData.productsID.length;i++)
                                {
                                    this.refs.push(db.doc('Products/' + this.categoryData.productsID[i]).get())
                                }
                                const result = await Promise.all(this.refs);
                                for(let i=0;i<result.length;i++)
                                {
                                    let data = result[i].data();
                                    if(data === undefined)
                                    {
                                        // If Data doesnt exsist clean up for next reload use;
                                        db.collection("Categories").doc(this.category).update({
                                        productsID: firebase.firestore.FieldValue.arrayRemove(result[i].id)
                                        }).catch((error)=>
                                        {
                                            console.log(error);
                                        });
                                    }else
                                    {
                                        if(data.active && data.category === this.categoryData.name){
                                            this.products.push(data);
                                        }
                                    }
                                }
                                let filteredProducts = [];
                                // Go with products one by one
                                for(let i=0; i< this.products.length;i++)
                                {
                                    let product = this.products[i];
                                    // Check if product is in specific specs
                                    if(chosenValues.subcat.includes(product.subcategory))
                                    {
                                        // Check price range
                                        if(product.basePrice>=chosenValues.minPrice && product.basePrice<=chosenValues.maxPrice)
                                        {
                                            if(this.filterOptions.length>0)
                                            {
                                                
                                                let amountOfOptions = this.filterOptions.length;
                                                let optionsPassed = 0;
                                                for(let y=0;y<amountOfOptions;y++)
                                                {
                                                    let currentOption = this.filterOptions[y];
                                                    if(chosenValues[currentOption.filterQueryTarget] === undefined)
                                                    {
                                                    chosenValues[currentOption.filterQueryTarget] = []; 
                                                    }
                                                    if(chosenValues[currentOption.filterQueryTarget].includes(eval(`product.${currentOption.filterQueryTarget}`)))
                                                    {
                                                        optionsPassed++;
                                                    }
                                                }
                                                if(optionsPassed === amountOfOptions)
                                                {
                                                
                                                    filteredProducts.push(product);
                                                }
                                            }
                                            else
                                            {
                                                filteredProducts.push(product);
                                            }
                                        }
                                    }
                                }
                                this.applyFilter({
                                    toggled: true,
                                    products: filteredProducts,
                                    currentValues: chosenValues
                                })
                                this.turnOffLoading();
                                // this.toggleFilters()
                            }else{
                            this.turnOffLoading();
                            }
                        }
                        else
                        {
                            this.productBucket = [];
                            this.turnOffLoading();
                            console.log("Error: Request handled,but no data on query.")
                        }
                    }).catch((error)=>
                    {
                        console.log('Error: Cannot get categories. More:')
                        console.log(error);
                    })    
                }
                else
                {
                    console.log("Error: Request handled,but no data on category.")
                }
            }).catch((error)=>
            {
                console.log('Error: Cannot get categories. More:')
                console.log(error);
            })
        }
    },
    watch:
    {
        $route(from,to)
        {
            if(from.name === to.name && from.path !== to.path)
            {
                window.scrollTo(0,0);
                this.clearData();
                this.getData();
            }
        }
    },
    created()
    {
        // console.log(this.$route.name)
        window.scrollTo(0,0);
        this.getData();
    },
    mounted()
    {
        window.scrollTo(0,0);
        setTimeout(()=>
        {
            this.renderFilters = true;
        },2500);
    },
    components: {navbar,trust,ftr,emptyState,productTile,loader,selector, filterModal},
    }
</script>
<style lang="scss" scoped>
  // Color Variables Import
  @import '../styles/variables';

    h1 {
        text-align: left;
        user-select: none;
        margin: 0;
        padding: 0;
        font-family: 'Poppins', sans-serif !important;
        font-weight: 500;
        font-size: 1.25rem
    }

    h3
    {
        text-align: left;
        font-weight: 400;
        padding: 0;
        font-size: 1rem;
        margin: 0;
    }
    
    .category
    {
        font-family: 'Poppins', sans-serif !important;
        width: 100vw;
        margin-top: 10.527em;
        display: flex;
        flex-direction: column;

        &__container
        {
            height: auto;
            padding-left: 5%;
            padding-right: 5%;
            margin-bottom: 1.5rem;


            &__filters
            {
                max-width: 100%;
                overflow: hidden;
                display: flex;
                flex-direction: column;
                user-select: none;
                &__header
                {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-weight: 500;
                    font-size: 0.8rem;
                    height: 1.563rem;
                    margin-right: 0.41rem;
                    border-bottom: 1px solid $border;
                    transition: all 0.4s ease-in;
                    cursor: pointer;

                    span
                    {
                        display: flex;
                        i{
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            margin-right: 0.21rem;
                        }
                        i::before{
                            display: flex;
                            justify-content: center;
                            align-items: center;
                        }
                    }
                }

                &__header:hover
                {
                    opacity: 0.4;
                }

                &__section
                {
                    margin-top: 0.512rem;
                    &__name
                    {
                        margin-top: 0.512rem;
                        margin-bottom: 0.512rem;
                        font-size: 0.8rem;
                        font-weight: 500;
                    }

                    &__control 
                    {
                        margin-top: 1rem;
                        display: flex;
                        align-items: center;
                        justify-content: flex-end;

                        button
                        {
                            margin-right: 1rem;
                            border: none;
                            padding-top: 0.64rem;
                            padding-bottom: 0.64rem;
                            padding-left: 1.25rem;
                            padding-right: 1.25rem;
                            color: white;
                            border-radius: 5px;
                            cursor: pointer;
                        }

                        button:hover
                        {
                            opacity: 0.8;
                        }

                        .redButton
                        {
                            background-color: $red;
                        }

                        .grayButton
                        {
                            background-color: $gray;
                        }
                    }

                    &__options
                    {
                        display: grid;
                        grid-template-columns: repeat(3, minmax(0, 1fr));
                        align-items: center;
                        

                        &__price
                        {
                            display: flex;
                            align-items: center;
                            padding-left: 1rem;

                            &__container
                            {
                                display: flex;
                                flex-direction: column;

                                &__name
                                {
                                    font-size: 0.8rem;
                                    font-weight: 400;
                                }

                                &__priceInput
                                {
                                    margin-right: 1.25rem;

                                    input
                                    {
                                        outline-color: $red;
                                        background: none;
                                        width: 5.96rem;
                                        padding: 0.64rem;
                                        font-size: 0.8rem;
                                        border: none;
                                        border-bottom: 1px solid $gray;
                                    }

                                    span
                                    {
                                        margin-left: 0.512rem;
                                        font-size: 0.8rem;
                                        color: $gray;
                                    }
                                }
                            }
                        }
                    }
                }

            }

            &__loadMore
            {
                margin-bottom: 1.25rem;
                display: flex;
                width: 100vw;
                justify-content: center;
                align-items: center;

                button
                {
                    background: none;
                    font-size: 1rem;
                    border: 2px solid $red;
                    color: $red;
                    padding-top: 1rem;
                    padding-bottom: 1rem;
                    padding-left: 3.125em;
                    padding-right: 3.125em;
                    border-radius: 5px;
                    transition: all 0.4s ease-in-out;

                }

                button:hover
                {
                    cursor: pointer;
                    color: $redHover;
                    border: 2px solid $redHover;
                    transform: translateY(-2px);
                    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
                }
            }

            &__texts
            {
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-bottom: 1.25rem;
                margin-top: 1.25rem;
                &__column{
                    display: flex;
                    flex-direction: column;
                }
            }

            &__infoFilter
            {
                display: flex;
                align-items: center;
                font-size: 0.8rem;
                margin-bottom: 1rem;
                font-weight: 500;
                color: $red;
                user-select: none;

                span
                {
                    margin-right: 0.64rem;
                }

                div
                {
                    font-size: 1.25rem;
                    cursor: pointer;
                }

                div:hover
                {
                    opacity: 0.6;
                }

            }

            &__productGrid
            {
                display: flex;
                flex-direction: column;
                width: 100%;
                height: auto;

                &__row
                {
                    display: flex;
                    justify-content: space-around;
                    width: 100%;
                    margin-bottom: 2rem;
                }
            }
        }
    }



    @media (max-width: 1024px)
    {
        .category__container__productGrid__row
        {
            flex-direction: column;
            height: auto !important;
            justify-content: center;
            align-items: center;
        }

        .category__container__filters__section__options
        {
            grid-template-columns: repeat(2, minmax(0, 1fr));
        }

        .category
        {
            &__container
            {
                &__filters
                {
                    justify-content: center;
                    
                }
            }
        }
    }


    @media (max-width: 770px)
    {
        .category
        {
            &__container
            {
                &__filters
                {
                    flex-direction: column;
                    
                    &__filter
                    {
                        margin-top: 1rem;
                        margin-bottom: 1rem;
                    }
                }
            }
        }

        .category__container__filters__section__options
        {
            grid-template-columns: repeat(1, minmax(0, 1fr));
        }

    }
    @media (max-width: 769px) {
        .category{
            margin-top: 5.96em;
        }
    }
    @media (max-width: 564px) {
        .category__container__filters__header{
            height: auto;
        }
    }
  
input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button {  
   opacity: 1;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>