<template>
    <div class="vat">
        <div class="vat__content">
            <div class="vat__content__header">
                <h1><span>K</span> - jak Komputery bez VAT</h1>
                <p>Nasze sprzęty trafiły już do kilkuset placówek oświatowych na terenie całej Polski. Główny powód to bogata oferta i bezpieczeństwo zakupów. Oferujemy gwarancję do 36 miesięcy i kompleksową pomoc techniczną. Zazwyczaj budżet na urządzenia szkolne jest ograniczony, dlatego proponujemy indywidualne, fachowe i bezpłatne doradztwo przy zakupie sprzętu, jak i wsparcie w trakcie jego użytkowania.</p>
            </div>
            <div class="vat__content__section">
                <h4>Dla kogo sprzęt z 0% VAT?</h4>
                <div class="vat__content__section__boxes">
                    <div class="vat__content__section__boxes__box">
                        <div class="vat__content__section__boxes__box__image">
                            <img src="../assets/vat0/szkola.png" alt="tba">
                        </div>
                        <div class="vat__content__section__boxes__box__texts">
                            <p>Instytucje Oświaty - Publiczne i Niepubliczne <span>(Szkoły, Przedszkola, Szkoły Wyższe)</span></p>
                        </div>
                    </div>
                    <div class="vat__content__section__boxes__box">
                        <div class="vat__content__section__boxes__box__image">
                            <img src="../assets/vat0/instytucja.png" alt="tba">
                        </div>
                        <div class="vat__content__section__boxes__box__texts">
                            <p>Instytucje Opiekuńczo-Wychowawcze</p>
                        </div>
                    </div>
                    <div class="vat__content__section__boxes__box">
                        <div class="vat__content__section__boxes__box__image">
                            <img src="../assets/vat0/organizacja.png" alt="tba">
                        </div>
                        <div class="vat__content__section__boxes__box__texts">
                            <p>Organizacje charytatywne, humanitarne lub edukacyjne, przekazujące nieodpłatnie sprzęt placówkom oświatowym.</p>
                        </div>
                    </div>
                </div>
            </div>

            <div class="vat__content__section">
                <h4>Jaki sprzęt z 0% VAT?</h4>
                <div class="vat__content__section__boxes">
                    <div class="vat__content__section__boxes__box">
                        <div class="vat__content__section__boxes__box__image">
                            <img src="../assets/vat0/komputer.png" alt="tba">
                        </div>
                        <div class="vat__content__section__boxes__box__texts">
                            <p>Komputery i zestawy komputerów stacjonarnych</p>
                        </div>
                    </div>
                    <div class="vat__content__section__boxes__box">
                        <div class="vat__content__section__boxes__box__image">
                            <img src="../assets/vat0/monitor.png" alt="tba">
                        </div>
                        <div class="vat__content__section__boxes__box__texts">
                            <p>Monitory</p>
                        </div>
                    </div>
                    <div class="vat__content__section__boxes__box">
                        <div class="vat__content__section__boxes__box__image">
                            <img src="../assets/vat0/drukarka.png" alt="tba">
                        </div>
                        <div class="vat__content__section__boxes__box__texts">
                            <p>Drukarki, skanery, serwery, urządzenia do transmisji danych</p>
                        </div>
                    </div>
                </div>
            </div>

             <div class="vat__content__section">
                <h4>Warunki do spełnienia</h4>
                <div class="vat__content__section__boxes">
                    <div class="vat__content__section__boxes__box">
                        <div class="vat__content__section__boxes__box__image">
                            <img src="../assets/vat0/zalacznik.png" alt="tba">
                        </div>
                        <div class="vat__content__section__boxes__box__texts">
                            <p>Pobrać i wypełnić formularz zamówienia</p>
                        </div>
                    </div>
                    <div class="vat__content__section__boxes__box">
                        <div class="vat__content__section__boxes__box__image">
                            <img src="../assets/vat0/formularz.png" alt="tba">
                        </div>
                        <div class="vat__content__section__boxes__box__texts">
                            <p>Potwierdzić zamówienie od organu prowadzącego placówkę oświatową</p>
                        </div>
                    </div>
                    <div class="vat__content__section__boxes__box">
                        <div class="vat__content__section__boxes__box__image">
                            <img src="../assets/vat0/wysylka.png" alt="tba">
                        </div>
                        <div class="vat__content__section__boxes__box__texts">
                            <p>Wypełniony formularz przesłać na sklep@kompre.pl</p>
                        </div>
                    </div>
                </div>
            </div>

            <div class="vat__content__section">
                <h4>Formularz do pobrania</h4>
                <div class="vat__content__section__boxes download">
                    <div class="vat__content__section__boxes__box">
                        <div class="vat__content__section__boxes__box__image download">
                            <a href="https://firebasestorage.googleapis.com/v0/b/kompreshop.appspot.com/o/vat0%2Fzamowienie%20bez%20VAT%20-%20KOMPRE.pdf?alt=media&token=61518e29-e775-49ca-8463-1b95e28c3eab">
                                <img src="../assets/vat0/PDF.png" alt="tba">
                            </a>
                        </div>
                    </div>
                    <div class="vat__content__section__boxes__box">
                        <div class="vat__content__section__boxes__box__image download">
                            <a href="https://firebasestorage.googleapis.com/v0/b/kompreshop.appspot.com/o/vat0%2Fzamowienie%20bez%20VAT%20-%20KOMPRE.docx?alt=media&token=bbfb0d0f-7b10-4f6e-abb7-7e261e819a5f">
                                <img src="../assets/vat0/doc.png" alt="tba">
                            </a>
                        </div>
                    </div>
                </div>
            </div>

            <div class="vat__content__section">
                <h4>Zaufali nam</h4>
                <div class="vat__content__section__trust">
                    <div class="vat__content__section__trust__image">
                        <img src="../assets/vat0/trust.png" alt="tba">
                    </div>
                    <div class="vat__content__section__trust__texts">
                        <h6>Politechnika koszalińska</h6>
                        <p>„Polecamy Firmę Kompre jako rzetelnego dostawcę dla innych Zamawiających. Jakość
zakupionych i eksploatowanych przez nas urządzeń nie budzi zastrzeżeń”</p>
                    </div>
                </div>
            </div>
        </div>
        <navbar/>
        <trust/>
        <ftr/>
    </div>
</template>
<script>
    // Imports
    import navbar from "../components/navbarStore.vue";
    import trust from "../components/trust.vue";
    import ftr from "../components/footer.vue";

    export default {
        data() {
            return {
            }
        },
        methods:
        {

        },
        mounted()
        {
            window.scrollTo(0,0);
        },
        components: {navbar,trust,ftr},
    }
</script>
<style lang="scss" scoped>
    @import '../styles/variables';

.download{
    justify-content: center !important;
    margin-bottom: 0 !important;
}


    .vat
    {
        width: 100vw;
        height: auto;
        margin-top: 10.527em;
        &__content
        {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: 90%;
            padding-left: 5%;
            padding-right: 5%;
            &__header{
                margin-top: 1.563rem;
                margin-bottom: 1rem;
                width: 55%;
                h1{
                    font-size: 1.563rem;
                    font-weight: 600;
                    text-align: center;
                    padding: 0;
                    margin: 0;
                    margin-bottom: 0.41rem;

                    span{
                        font-size: 1.953rem !important;
                        color: #f01827;
                    }
                }
                h4{
                    margin: 0;
                    padding: 0;
                    text-align: center;
                    font-size: 1rem !important;
                    font-weight: 500;
                    margin-bottom: 0.21rem;
                }
                p{
                    font-size: 1rem;
                    margin: 0;
                    padding: 0;
                    text-align: center;
                }
            }
            &__section{
                display: flex;
                flex-direction: column;
                align-items: center;
                margin-bottom: 1.953rem;
                h4{
                    font-size: 1.25rem;
                    text-align: center;
                    margin: 0;
                    padding: 0;
                    margin-bottom: 1.25rem;
                    font-weight: 500;
                }
                &__boxes{
                    display: flex;
                    justify-content: space-between;
                    width: 80%;
                    &__box{
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        width: 30%;
                        height: auto;
                        &__image{
                            display: flex;
                            justify-content: center;
                            max-height: 7.451rem;
                            width: 50%;
                            margin-bottom: 1.25rem;
                            img{
                                max-height: 100%;
                                max-width: 100%;
                            }
                        }
                        &__texts{
                            margin-top: auto;
                            margin-bottom: auto;
                            p{
                                font-size: 1rem;
                                font-weight: 400;
                                padding: 0;
                                margin: 0;
                                text-align: center;
                                span{
                                display: block;
                                }
                            }
                        }
                    }
                }
                &__trust{
                    display: flex;
                    height: 100%;
                    width: 52%;
                    &__image{
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        max-height: 5.96rem;
                        width: 20%;
                        padding-right: 1rem;
                        img{
                            max-width: 100%;
                            max-height: 100%;
                        }
                    }
                    &__texts{
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        width: 80%;
                        h6{
                            font-size: 1rem;
                            font-weight: 500;
                            margin: 0;
                            padding: 0;
                            margin-bottom: 0.21rem;
                        }
                        p{
                            margin: 0;
                            padding: 0;
                            font-weight: 400;
                            font-size: 0.8rem;
                        }
                    }
                }
            }
        }
    }


    @media (max-width: 1024px)
    {
     .vat__content{
         &__header{
             width: 80%;
         }
         &__section{
            &__boxes{
                width: 100%;
            }  
            &__trust{
                width: 80%;
                justify-content: center;
                align-items: center;
                &__image{
                    height: 100%;
                    width: 5.96rem;
                }
            }
        }
     }

    .download{
        flex-direction: row !important;
    }
    }
    @media (max-width: 769px) {
    .vat{
        margin-top: 4.768em;
        &__content{
            &__header{
                width: 100%;
            }
            &__section{
                &__boxes{
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    &__box{
                        margin-bottom: 1rem;
                        width: 80%;
                        height: auto;
                        &__image{
                            width: 7.451rem;
                            height: auto;
                        }
                    }
                }
                &__trust{
                    width: 100%;
                }
            }
        }
    }
}

    @media (max-width: 370px) {
    .vat{
        &__content{
            &__section{
                margin-bottom: 0.64rem;
                h4{
                    font-size: 1rem;
                    margin-bottom: 0.41rem;
                }
                &__boxes{
                    &__box{
                        &__image{
                            width: 4.768rem;
                            height: auto;
                            margin-bottom: 0.64rem;
                        }
                    }
                }
                &__trust{
                    flex-direction: column;
                    margin-top: 0.41rem;
                    margin-bottom: 1rem;
                    &__image{
                        width: 4.768rem;
                        margin-bottom: 0.64rem;
                    }
                    &__texts{
                        align-items: center;
                        p{
                            text-align: center;
                        }
                    }
                }
            }
            &__header{
                margin-top: 1rem;
                margin-bottom: 1rem;
                h1{
                    font-size: 1.25rem !important;
                }
            }
        }
    }
    .download{
        margin-top: 0.41rem;
    }
}

</style>