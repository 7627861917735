<template>
 <div class="dashboardNewsletter">
    <loader v-if="!rendered">
    </loader>
    <div class="dashboardNewsletter__title" v-if="rendered">
        <span class="md-display-1">Panel newslettera</span>
    </div>

    <div class="dashboardNewsletter__info" v-if="rendered">
                <md-toolbar :md-elevation="1">
                    <span class="md-headline">Personalizowana wiadomość do wszystkich zarejestrowanych użytkowników.</span>
                </md-toolbar>
    </div>
   <div class="dashboardNewsletter__columns" v-if="rendered">
       
        <div class="dashboardNewsletter__columns__column">
            <div class="addNewItem__options">
                <div class="addNewItem__options__option">
                    <div class="addNewItem__options__option__doubleText">
                        <span class="md-title">Tytuł wiadomości</span>
                        <span class="md-body-1">Do każdego tytułu automatycznie zostaje dodane "| Kompre.pl"</span>
                    </div>
                <md-field id="title" :class="{'md-invalid': errors.title}">
                    <label>Tytuł</label>
                    <md-input v-model="newsletter.title"></md-input>
                    <span class="md-error">Brak tytułu.</span>
                </md-field>
                </div>
            </div>

        </div>
        <div class="dashboardNewsletter__columns__column">
            <div class="addNewItem__options">
                <div class="addNewItem__options__option">
                    <div class="addNewItem__options__option__doubleText">
                        <span class="md-title">Treść wiadomości</span>
                        <span class="md-body-1">Wymagany jest kod html.</span>
                    </div>
                <md-field id="title" :class="{'md-invalid': errors.content}">
                    <label>Kod HTML</label>
                    <md-input v-model="newsletter.content"></md-input>
                    <span class="md-error">Newsletter musi posiadać treść!</span>
                </md-field>
                </div>
                <md-button class="md-dense md-raised md-primary sendButton" @click="errorCheck()">Wyślij wiadomość</md-button>
                <span class="messageSent" v-if="messageSent">Wysłano wiadomość</span>
            </div>
        </div>
    </div>
    <!-- <span style="margin-left: auto;">Wysłano</span> -->

    <div class="dashboardNewsletter__postList mt" v-if="rendered">
      <md-table v-model="registeredUsers" md-card>
        <md-table-toolbar>
          <h1 class="md-title">Użytkownicy zarejestrownani do newslettera.</h1>
          <span class="md-caption" v-if="showRegisteredUsers === true">Kliknij "Usuń", aby usunąć e-mail z bazy danych, oraz sendgrida.</span>
          <span class="md-caption functional" v-if="showRegisteredUsers === false" @click="getRegisteredUsers()">Kliknij tutaj, aby załadować zarejestrowanych użytkowników (może zająć to dłuższą chwilę)</span>
        </md-table-toolbar>

        <md-table-row slot="md-table-row" slot-scope="{ item }" v-if="showRegisteredUsers === true">
          <md-table-cell md-label="ID" md-sort-by="id"><span class="md-body-2">{{item.id}}</span></md-table-cell>
          <md-table-cell md-label="E-mail" md-sort-by="value"><span class="md-body-1">{{item.mail}}</span></md-table-cell>
          <md-table-cell md-sort-by="active"><div class="deleteDiv"><span class="md-body-1 delete" @click="deleteUser(item.mail)">Usuń</span></div></md-table-cell>
        </md-table-row>
      </md-table>
    </div>
 </div>
</template>
<script>
import loader from '../components/loader.vue';
import {db, firebase} from "../configs/firebase";
    import axios from 'axios';
export default {
  data() {
    return {
        registeredUsers: [],
        rendered: false,
        showRegisteredUsers: false,
        messageSent: false,
        newsletter: {
            title: "",
            content: '<div style="height: 3px; background-color: black; margin: 0; padding: 0; background: rgb(2,0,36); background: linear-gradient(90deg, #CC6E4B 0%, #CC6E4B 0%, #BF5057 100%); max-width:100%;"></div><div style="margin-bottom: 8px; margin-top: 8px"><img src="https://firebasestorage.googleapis.com/v0/b/kompreshop.appspot.com/o/assets%2Fkomprelogo.png?alt=media&token=a10abe4c-8aaa-40b8-9348-969454f5988a"></div><div style="margin-top: 8px; margin-bottom: 4px; font-size: 16px; font-weight: 500">Drogi kliencie,</div><p style="margin: 0; text-align: justify; text-justify: inter-word; max-width:100%">Przykładowa wiadomość newslettera</p><div style="margin-top: 16px; font-size: 10.24px; opacity: 0.5; font-weight: 500; max-width: 100%">Aby anulować subskrypcję newslettera, skontaktuj się z nami <a href="http://kompre.pl/kontakt" style="color: #000000">TUTAJ</a></div><div style="margin-top: 8px; font-size: 10.24px; opacity: 0.5; font-weight: 500; max-width: 100%">Wiadomość została wygenerowana automatycznie. Odpowiedź na tę wiadomość zostanie przekazana bezpośrednio do obsługi.</div>'
        },
        errors: {
            title: false,
            content: false
        }
    }
  },
  props: ['userInfo'],
  methods: {
    async sendNewsletter(){
        this.rendered = false;
            try{
                const preparedData = {
                    title: this.newsletter.title,
                    content: this.newsletter.content,
                };
                
                    const logId = db.collection('Logs').doc()
                    db.collection('Logs').doc(logId.id).set({
                        message: `Newsletter ${this.newsletter.title} wysłany przez ${this.userInfo.name} ${this.userInfo.surname}`,
                    user: {
                        mail: this.userInfo.mail,
                        name: this.userInfo.name,
                        surname: this.userInfo.surname,
                    },
                        newsletter: preparedData,
                        createdAt: new Date(),
                        uniqueId: logId.id
                    })

                await axios.post('https://europe-central2-kompreshop.cloudfunctions.net/webApi/webApi/NewsletterMessage', preparedData).catch((error)=>
                {
                    console.log(error);
                })
            this.messageSent = true
            this.rendered = true;
            }
            catch(error)
            {
                console.log(error);
            }
    },
    errorCheck(){
        if(this.newsletter.title.length > 0){
            this.errors.title = false;
            if(this.newsletter.content.length > 0){
                this.errors.content = false;
                this.sendNewsletter();
            }else{
                this.errors.content = true;
                return 0;
            }
        }else{
            this.errors.title = true;
            return 0;
        }
    },
    async deleteUser(user){
        // console.log(user)
        this.rendered = false;
        await db.collection('Newsletter').doc('Registered').update({
            'Emails':  firebase.firestore.FieldValue.arrayRemove(user)
        });
        const preparedEmail = {
            "mail": user
        }
        await axios.post('https://europe-central2-kompreshop.cloudfunctions.net/webApi/DeleteFromNewsletter', preparedEmail).catch((error)=>
        {
            console.log(error);
        })
        this.getRegisteredUsers();
      },
    async getRegisteredUsers()
        {
            this.rendered = false;
            this.registeredUsers = [];
            this.showRegisteredUsers = true;
            try{
            const result = await db.collection("Newsletter").doc("Registered").get();
            let data = result.data()
            for(let i=0; i<data.Emails.length; i++)
            {
                let item = {
                    id: 0,
                    mail: ""
                };
                item.mail = data.Emails[i]
                item.id = i
              this.registeredUsers.push(item);
            }
            this.rendered = true;
            }
            catch(error)
            {
                console.log(error);
            }
        },
  },
    mounted(){
        this.rendered = true
    },
    created(){
    },
    updated(){
    },
  components: {loader}, 
}
</script>
<style lang="scss">
  // Color Variables Import
.dashboardNewsletter
{
    width: 100%;
    &__info{
        width: 100%;
    }
    &__title
    {
        margin-bottom: 1em;
        display: flex;
        align-items: center;
    }
    &__columns
    {
        width: 100%;
        display: flex;
        justify-content: space-between;

        &__column
        {
            display: flex;
            width: 45%;
        }
    }
            &__options
        {
            padding-top: 2em;
            width: 100%;

            &__option
            {
                &__doubleText
                {
                    display: flex;
                    flex-direction: column;
                }
            }

            &__newAddon
            {
                display: flex;
            }
        }
    &__nextStep
    {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        margin-bottom: 2em;
        margin-top: 2em;
    }
}
.sendButton{
    margin-left: 75% !important;
    margin-right: 0 !important;
    margin-top: 0 !important;
    // margin-bottom: 1rem !important;
}
.messageSent{
    color: #00aa39;
    font-size: 0.8rem;
    margin-left: 73.5%;
}
.deleteDiv{
    display: flex;
    justify-content: flex-end;
    padding-right: 1.25rem;
}
.delete{
    // text-justify: right;
    color: #c00000;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
}
.delete:hover{
    color: #ff0000;
}

.mt{
    margin-top: 2.441rem;
}

.functional{
    cursor: pointer;
    transition: all 0.2s ease-in-out;
}
.functional:hover{
    color: #000000 !important;
}
</style>