<template>
  <div class="category">
    <navbar/>
    <div class="category__container" v-if="!loading">
        <div class="category__container__texts">
            <div class="category__container__texts__column">
                <div class="category__container__texts__header">
                    <h1>Promocje</h1>
                </div>
                <div class="category__container__texts__subheader">
                    <h3>Zobacz najlepsze promocje z naszej oferty.</h3>
                </div>
            </div>
        </div>
        <div class="category__container__productGrid" v-if="sales.length > 0">
            <div v-for="(i, rowIndx) in Math.ceil(sales.length / 4)" :key="rowIndx" class="category__container__productGrid__row" data-aos="fade-up">
                <saleTile :product="product" v-for="product in sales.slice((i - 1) * 4, i * 4)" :key="product.id"></saleTile>
            </div>
        </div>
    </div>
    
    <loader v-if="loading">test</loader>
    <emptyState v-if="sales.length === 0 && !loading"></emptyState>
    <trust/>
    <ftr/>
    
    <transition name="fade">
        <filterModal @applyFilter="applyFilter" @resetFilter="resetFilter" :activeData="filterSettings" :options="filterOptions" :category="categoryData.name" :arrayOfRef="categoryData.productsID" v-if="showFilters === true" @close="closeModal"></filterModal>
    </transition>
  </div>
</template>
<script>

    // Imports
    import {
    db,firebase
    } from '../configs/firebase'
    // Imports of components
    import navbar from "../components/navbarStore.vue";
    import trust from "../components/trust.vue";
    import ftr from "../components/footer.vue";
    import emptyState from "../components/emptyState.vue";
    import saleTile from "../components/saleTile.vue";
    import loader from "../components/loader.vue";
    import selector from "../components/selector.vue";
    import filterModal  from '@/components/filterModal.vue';

    String.prototype.capitalize = function() {
        return this.charAt(0).toUpperCase() + this.slice(1);
    }

    export default {
        emits: ["toggleScroll"],
    data() {
        return {
            refs: [],
            products: [],
            renderFilters: false,
            resetInput: false,
            typeOfSearch: 'firebase',
            algolia: {
                result: null,
                filter: "",
                currentPage: 0,
                numberOfPages: 0
            },
            lastVisible: "",
            query: "",
            selectorValues: {
                manufacturer: "",
                qualityClass: "",
                subcategory: "",
                sortBy: "asc"
            },
            showFilters: false,
            productBucket: [],
            categoryData: null,
            lastItemID: "",
            limit: 24,
            loading: true,
            showMoreButton: true,
            // Filters
            filterOptions: [],
            filterSettings: null,
            sales: [],
        }
    },
    methods: {
        closeModal(){
            this.showFilters = false;
            this.$emit("toggleScroll");
        },
        getQuery()
        {
            let query = db.collection('Sales').where('display','==', true)
            // console.log(query);
            return query;
        },
        queryChange(data)
        {
            let selector = this.selectorValues;
            selector[data.name]= data.value;
        },
        // unpackData(data)
        // {
        //     let unpackedData = [];
        //     for(let i=0; i< data.docs.length; i++)
        //     {
        //         let currentItem = data.docs[i].data();
        //         {
        //             this.lastItemID = currentItem.uniqueID;
        //             this.lastVisible = currentItem;
        //         }
        //         unpackedData.push(currentItem);
        //     }
        //     return unpackedData;
        // },
        clearData()
        {
            this.loading = true;
            this.filterSettings = null;
            this.showFilters = false;
            this.typeOfSearch = 'firebase';
            this.showFilters = false;
            this.lastItemID = "";
            this.productBucket = [];
            this.categoryData = {};
            this.query = "";
            this.showMoreButton = true;
            this.algolia = {
                result: null,
                filter: "",
                currentPage: 0,
                numberOfPages: 0
            };
            this.filterOptions = [];
            // this.turnOffLoading();
        },
        turnOffLoading()
        {
                this.loading = false;
        },
        // Get data from db
        async getData() {   
            try {
                this.sales = []
                const result = await db.collection('Sales').where('display', '==', true).get()

                result.forEach(doc => {
                this.categoryData = doc.data();
                // console.log(this.categoryData);
                let sale = doc.data()
                sale.id = doc.id 
                this.sales.push(sale)
                
                this.turnOffLoading();
            });
                }
            catch(error) {
                console.log(error.message);
            }
        }
    },
    watch:
    {
        $route(from,to)
        {
            if(from.name === to.name && from.path !== to.path)
            {
                window.scrollTo(0,0);
                this.clearData();
                this.getData();
            }
        }
    },
    created()
    {
        window.scrollTo(0,0);
        this.getData();
    },
    components: {saleTile, navbar, trust, ftr, emptyState, loader, selector, filterModal},
    }
</script>
<style lang="scss" scoped>
  // Color Variables Import
  @import '../styles/variables';

    h1 {
        text-align: left;
        user-select: none;
        margin: 0;
        padding: 0;
        font-family: 'Poppins', sans-serif !important;
        font-weight: 500;
        font-size: 1.25rem
    }

    h3
    {
        text-align: left;
        font-weight: 400;
        padding: 0;
        font-size: 1rem;
        margin: 0;
    }
    
    .category
    {
        font-family: 'Poppins', sans-serif !important;
        width: 100vw;
        margin-top: 10.527em;
        display: flex;
        flex-direction: column;

        &__container
        {
            width: 90%;
            height: auto;
            padding-left: 5%;
            padding-right: 5%;
            margin-bottom: 1.563rem;


            &__filters
            {
                max-width: 100%;
                overflow: hidden;
                display: flex;
                flex-direction: column;
                user-select: none;
                &__header
                {
                    margin-top: 1rem;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-weight: 500;
                    font-size: 0.8rem;
                    height: 1.563rem;
                    margin-right: 0.41rem;
                    border-bottom: 1px solid $border;
                    transition: all 0.4s ease-in;
                    cursor: pointer;

                    span
                    {
                        display: flex;
                        i{
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            margin-right: 0.21rem;
                        }
                        i::before{
                            display: flex;
                            justify-content: center;
                            align-items: center;
                        }
                    }
                }

                &__header:hover
                {
                    opacity: 0.4;
                }

                &__section
                {
                    margin-top: 0.512rem;
                    &__name
                    {
                        margin-top: 0.512rem;
                        margin-bottom: 0.512rem;
                        font-size: 0.8rem;
                        font-weight: 500;
                    }

                    &__control 
                    {
                        margin-top: 1rem;
                        display: flex;
                        align-items: center;
                        justify-content: flex-end;

                        button
                        {
                            margin-right: 1rem;
                            border: none;
                            padding-top: 0.64rem;
                            padding-bottom: 0.64rem;
                            padding-left: 1.25rem;
                            padding-right: 1.25rem;
                            color: white;
                            border-radius: 5px;
                            cursor: pointer;
                        }

                        button:hover
                        {
                            opacity: 0.8;
                        }

                        .redButton
                        {
                            background-color: $red;
                        }

                        .grayButton
                        {
                            background-color: $gray;
                        }
                    }

                    &__options
                    {
                        display: grid;
                        grid-template-columns: repeat(3, minmax(0, 1fr));
                        align-items: center;
                        

                        &__price
                        {
                            display: flex;
                            align-items: center;
                            padding-left: 1rem;

                            &__container
                            {
                                display: flex;
                                flex-direction: column;

                                &__name
                                {
                                    font-size: 0.8rem;
                                    font-weight: 400;
                                }

                                &__priceInput
                                {
                                    margin-right: 1.25rem;

                                    input
                                    {
                                        outline-color: $red;
                                        background: none;
                                        width: 5.96rem;
                                        padding: 0.64rem;
                                        font-size: 0.8rem;
                                        border: none;
                                        border-bottom: 1px solid $gray;
                                    }

                                    span
                                    {
                                        margin-left: 0.512rem;
                                        font-size: 0.8rem;
                                        color: $gray;
                                    }
                                }
                            }
                        }
                    }
                }

            }

            &__loadMore
            {
                margin-bottom: 1.25rem;
                display: flex;
                width: 100vw;
                justify-content: center;
                align-items: center;

                button
                {
                    background: none;
                    font-size: 1rem;
                    border: 2px solid $red;
                    color: $red;
                    padding-top: 1rem;
                    padding-bottom: 1rem;
                    padding-left: 3.125em;
                    padding-right: 3.125em;
                    border-radius: 5px;
                    transition: all 0.4s ease-in-out;

                }

                button:hover
                {
                    cursor: pointer;
                    color: $redHover;
                    border: 2px solid $redHover;
                    transform: translateY(-2px);
                    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
                }
            }

            &__texts
            {
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-bottom: 1.25rem;
                &__column{
                    display: flex;
                    flex-direction: column;
                }
            }

            &__infoFilter
            {
                display: flex;
                align-items: center;
                font-size: 0.8rem;
                margin-bottom: 1rem;
                font-weight: 500;
                color: $red;
                user-select: none;

                span
                {
                    margin-right: 0.64rem;
                }

                div
                {
                    font-size: 1.25rem;
                    cursor: pointer;
                }

                div:hover
                {
                    opacity: 0.6;
                }

            }

            &__productGrid
            {
                display: flex;
                flex-direction: column;
                width: 100%;
                height: auto;

                &__row
                {
                    display: flex;
                    justify-content: space-around;
                    width: 100%;
                    height: 42vh;
                    margin-bottom: 1.953rem;
                }
            }
        }
    }



    @media (max-width: 1024px)
    {
        .category__container__productGrid__row
        {
            flex-direction: column;
            height: auto !important;
            justify-content: center;
        }

        .category__container__filters__section__options
        {
            grid-template-columns: repeat(2, minmax(0, 1fr));
        }

        .category
        {
            &__container
            {
                &__filters
                {
                    justify-content: center;
                    
                }
            }
        }
    }


    @media (max-width: 770px)
    {
        .category
        {
            &__container
            {
                &__filters
                {
                    flex-direction: column;
                    
                    &__filter
                    {
                        margin-top: 1rem;
                        margin-bottom: 1rem;
                    }
                }
            }
        }

        .category__container__filters__section__options
        {
            grid-template-columns: repeat(1, minmax(0, 1fr));
        }

    }
    @media (max-width: 769px) {
        .category{
            margin-top: 5.96em;
        }
    }
    @media (max-width: 564px) {
        .category__container__filters__header{
            height: auto;
        }
    }
  
input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button {  
   opacity: 1;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>