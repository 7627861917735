<template>
    <div class="footer">
        <div class="footer__row">
            <div class="footer__row__column">
                <div class="footer__row__column__name">
                    <span>Kategorie</span>
                </div>
                <div class="footer__row__column__links">
                    <div class="footer__row__column__links__link" v-for="category in categories" :key="category.sort">
                        <router-link :class="{routeDisabled: routeName === category.link}" :to="`/kategorie/${category.link}`">{{category.name}}</router-link>
                    </div>
                </div>
            </div>
            <div class="footer__row__column">
                <div class="footer__row__column__name">
                    <span>Informacje</span>
                </div>
                <div class="footer__row__column__links">
                    <div class="footer__row__column__links__link">
                        <router-link :to="`/regulamin`">Regulamin</router-link>
                    </div>
                    <div class="footer__row__column__links__link">
                        <router-link :to="`/politykaprywatnosci`">Polityka Prywatności</router-link>
                    </div>
                    <div class="footer__row__column__links__link">
                        <router-link :to="`/koszyk`">Koszyk</router-link>
                    </div>
                    <div class="footer__row__column__links__link">
                        <router-link :to="`/kontakt`">Kontakt</router-link>
                    </div>
                    <div class="footer__row__column__links__link">
                        <router-link :to="`/reklamacje`">Reklamacje i zwroty</router-link>
                    </div>
                    <div class="footer__row__column__links__link">
                        <a href="https://mojapaczka.dpd.com.pl/login" target="_blank">Śledzenie Przesyłek</a>
                    </div>
                    <div class="footer__row__column__links__link">
                        <a href="https://blog.kompre.pl/" target="_blank">Blog</a>
                    </div>
                </div>
            </div>
            <div class="footer__row__column">
                <div class="footer__row__column__name">
                    <span>Kontakt</span>
                </div>
                <div class="footer__row__column__contact">
                    <div class="footer__row__column__contact__section">
                        <div class="footer__row__column__contact__section__icon">
                            <i class="fi-rr-paper-plane icon"></i>
                        </div>
                        <div class="footer__row__column__contact__section__info">
                            <span class="title">KOMPRE Sp. z o. o.</span>
                            <span>Rogaszyce, ul. Wesoła 37</span>
                            <span>63-500 Ostrzeszów</span>
                            <span>Otwarte: 9.00 - 16.00</span>
                        </div>
                    </div>
                    <div class="footer__row__column__contact__section border">
                        <div class="footer__row__column__contact__section__icon">
                            <i class="fi-rr-smartphone icon"></i>
                        </div>
                        <div class="footer__row__column__contact__section__info ">
                            <div>
                                <span class="title">Dział Sprzedaży</span>
                                <span><a href="tel:+48510188531" class="hoverable">+48 510 188 531</a></span>
                            </div>
                            <div>
                                <span class="title">Wsparcie Techniczne</span>
                                <span><a href="tel:+48661801461" class="hoverable">+48 661 801 461</a></span>
                            </div>
                        </div>
                    </div>
                    <div class="footer__row__column__contact__section border">
                        <div class="footer__row__column__contact__section__icon">
                            <i class="fi-rr-envelope icon"></i>
                        </div>
                        <div class="footer__row__column__contact__section__info">
                            <div>
                                <span class="title">Dział Sprzedaży</span>
                                <span><a href="mailto:sklep@kompre.pl" class="hoverable">sklep@kompre.pl</a></span>
                            </div>
                            <div>
                                <span class="title">Reklamacje</span>
                                <span><a href="mailto:serwis@kompre.pl" class="hoverable">serwis@kompre.pl</a></span>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="footer__skwebhouse">
            <a href="https://complaia.systems/" target="_blank"><span> Made with <i class="fi-rr-heart webhouse"></i> by Complaia Systems 2022. </span></a>
        </div>
    </div>
</template>
<script>
    // Imports
    import {
    db
    } from '../configs/firebase'

    export default {
        data() {
            return {
                categories: [],
                routeName: ""
            }
        },
        methods: {
            getCurrentRoute()
            {
                if(this.$route.params.category !== undefined)
                {
                    this.routeName = this.$route.params.category;
                }
            }
        },
        watch:
        {
            $route(to, from)
            {
                this.getCurrentRoute();
            }
        },
        beforeCreate()
        {
                //Get Categories from db
                db.collection('Categories').where('showInFooter',"==", true).get().then((data)=>
                {
                    // Unpack categories
                    for(let i=0; i < data.docs.length; i++)
                    {
                        this.categories.push(data.docs[i].data());
                    }
                    // Sort categories
                    this.categories.sort(function(a,b)
                    {
                        return a.sort - b.sort
                    })
                }).catch((error)=>
                {
                    console.log('Error: Cannot get categories. More:')
                    console.log(error);
                })
        },
        created()
        {
            this.getCurrentRoute();
        }
    }
</script>
<style lang="scss" scoped>
  // Color Variables Import
  @import '../styles/variables';

    .hoverable:hover
    {
        color: $red;
    }


    .webhouse
    {
        color: $red;
    }
    .title
    {
        font-weight: 500;
    }

    .icon
    {
        color: $gray;
        font-size: 1.25rem;
    }
    .footer
    {
            width: 90vw;
            display: flex;
            height: auto;
            padding-left: 5vw;
            padding-right: 5vw;
            justify-content: center;
            align-items: center;
            flex-direction: column;

            &__row
            {
                display: flex;
                align-items: flex-start;
                justify-content: space-between;
                width: 100%;
                margin-bottom: 1.25rem;
                

                &__column
                {
                    display: flex;
                    width: 30%;
                    flex-direction: column;

                    &__name
                    {
                        user-select: none;
                        font-size: 1.25rem;
                        font-weight: 500;
                        padding-top: 1.25rem;
                        padding-bottom: 1.25rem;
                        margin-bottom: 1.25rem;
                        border-bottom: 1px solid $border;
                    }

                    &__contact
                    {
                        width: 100%;
                        display: flex;
                        flex-direction: column;
                        
                        &__section
                        {
                            display: flex;
                            align-items: center;
                            margin-bottom: 1.25rem;
                            &__info
                            {
                                display: flex;
                                flex-direction: column;
                                margin-left: 1.25rem;

                                div
                                {
                                    margin-top: 0.64rem;
                                    display: flex;
                                    flex-direction: column;
                                }
                            }
                        }
                    }

                    &__links
                    {
                        display: flex;
                        flex-direction: column;
                        width: 100%;
                        
                        &__link
                        {
                            
                            padding-bottom: 1.25rem;

                            a
                            {
                                transition: transform 0.4s ease-in;
                            }

                            a:hover
                            {
                                transform: translateX(15px);
                                color: $red;
                            }

                        }
                    }
                }
            }

            &__skwebhouse
            {
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 0.8rem;
                color: $gray;
                padding-bottom: 1.25rem;
                a{
                    span
                    {
                        display: block !important;
                    }
                }
            }



            &__skwebhouse:hover
            {
                color: $red;
                
            }

            &__skwebhouse:hover .webhouse
            {
                color: $gray;
            }
    }

    @media (max-width: 769px)
    {
        .footer
        {
            &__row
            {
                flex-direction: column;

                &__column
                {
                    width: 100%;
                }
            }
        }
    }

    .routeDisabled
    {
        pointer-events: none;
        color: $red;
        cursor: not-allowed;
    }

    .border
    {
        width: 100%;
        border-top: 1px solid $border;
    }

</style>