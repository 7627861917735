<template>
  <div class="homepage">
    <navbar/>
    <loader v-if="loading" />
    <banner v-if="this.bannerActive && !loading" />
    <slider v-if="this.sliderActive && !loading" />
    <suggestedProducts v-if="!loading" :amount="6" :category="'Promoted'" :subtext="'Zobacz najlepsze przedmioty z naszej oferty.'" :head="'Polecane'"/>
    <suggestedProducts v-if="!loading" :amount="3" :category="'Monitory'" :subtext="'Sprzęt poleasingowy w klasie A.'" :head="'Monitory Używane'"/>
    <blogPreview v-if="!loading"/>
    <newsletter v-if="!loading"/>
    <opinions v-if="!loading"/>
    <trust v-if="!loading"/>
    <ftr v-if="!loading"/>
  </div>
</template>
<script>
// Imports of components
import navbar from "../components/navbarStore.vue";
import banner from "../components/slider.vue";
import slider from "../components/banner.vue";
import suggestedProducts from "../components/suggestedProducts.vue";
import blogPreview from "../components/blogPreview.vue";
import trust from "../components/trust.vue";
import newsletter from "../components/newsletter.vue";
import opinions from "../components/opinions.vue";
import ftr from "../components/footer.vue";
import {db,storage, firebase} from "../configs/firebase";
import loader from "../components/loader.vue";

export default {
  data() {
    return {
      bannerActive: false,
      sliderActive: false,
      loading: true
    }
  },
  async created() {
    this.dbListener = await db.collection('SliderRules').doc('Rules').get()
    this.rules = this.dbListener.data()
    this.bannerActive = this.rules.isBannerActive 
    this.sliderActive = this.rules.isSliderActive
    this.loading = false
  },
  methods: {

  },
  mounted()
  {
    window.scrollTo(0,0);
  },
  components: {navbar,
  slider,
   banner, suggestedProducts, blogPreview, trust, ftr, newsletter, opinions, loader},
}
</script>
<style lang="scss">

  // Color Variables Import
  

  .homepage
  {
    font-family: 'Poppins', sans-serif !important;
    width: 100vw;
    margin-top: 10.117em;
    overflow-x: hidden;
  }
  @media (max-width: 769px) {
    .homepage{
        margin-top: 4.768em;
    }
  }
  

</style>